import React, { useEffect, useRef, useState } from "react";
import Userdashsidebar from "../Userdashsidebar";
import "../../css/dashboardnewstyles.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  totalProjectsUrl,
  updateProjectStatusPendingUrl,
  userDashboardStatusUrl,
  userProfileURL,
} from "../../globalURL";
import UseServices from "../../services/api";

import { toast } from "react-toastify";
import Spinner from "../project/Spinner";
import DownloadButton from "../../common/download_button";
import { getIpAddress } from "../../common/common_helper";

const Dashboardinfo = () => {
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isReadOnlyEnable, setIsReadOnlyEnable] = useState(false);
  const [allProjectsList, setAllProjectsList] = useState([]);

  const [allstats, setAllstats] = useState({
    all_projects: 0,
    completed_projects: 0,
    pending_projects: 0,
    support_requests: 0,
    user_has_package: 0,
  });
  const [userDetails, setuserDetails] = useState({
    access_token: localStorage.getItem("access_token"),
  });

  const timestamp = userDetails?.last_login_time;
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleString();

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    getUserProfileDetails();
    fetchAllProjects();
    dashStats();
  }, []);

  const getUserProfileDetails = () => {
    UseServices.fetchPostData(userProfileURL, {
      access_token: userDetails.access_token,
    })
      .then((response) => {
        setIsLoading(false);
        // console.log("result : ")
        // console.log(response.data)
        // set user details based on response data
        if (response.data) {
          setuserDetails(response.data);
        } else {
          toast.error("User not authorized");
          // alert("TEST")
          localStorage.clear();
          navigate("/login");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something error occured..!, Please try again.");
        console.error(error);
      });
  };

  const fetchAllProjects = (page, page_limit) => {
    const { access_token } = userDetails;
    axios
      .post(
        totalProjectsUrl,
        JSON.stringify({ page: 1, page_limit: 10, access_token: access_token }),
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.status === "valid") {
          // console.log("response", response.data.data.projects)
          setAllProjectsList(response.data.data.projects);
          // alert(response.data.projects);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something error occured..!, Please try again.");
        // alert("failed")
        console.log(error);
      });
  };

  const dashStats = () => {
    const { access_token } = userDetails;
    axios
      .post(
        userDashboardStatusUrl,
        JSON.stringify({ access_token: access_token }),
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.status === "valid") {
          // console.log("response", response.data.data)
          setAllstats(response.data.data);
          // alert(response.data.projects);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something error occured..!, Please try again.");
        // alert("failed")
        console.log(error);
      });
  };
  const editProjectProgram = (projectPhase, projectID) => {
    // projectID
    localStorage.setItem("project_id", projectID);
    localStorage.setItem("phase", projectPhase);
    const path =
      projectPhase === "As Operated" || projectPhase === "Operations Phase"
        ? "/operational"
        : "/projectinformation";
    navigate(path, { state: { project_id: projectID } });
  };

  const updateProjectStatusPending = (projectPhase, projectID) => {
    localStorage.setItem("phase", projectPhase);
    localStorage.setItem("project_id", projectID);

    const path =
      projectPhase === "As Operated" || projectPhase === "Operations Phase"
        ? "/operational"
        : "/chiller";

    const { access_token } = userDetails;
    const project_id = localStorage.getItem("project_id");
    if (project_id) {
      axios
        .post(
          updateProjectStatusPendingUrl,
          JSON.stringify({
            project_status: "pending",
            project_id,
            access_token,
          }),
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          setIsLoading(false);
          if (response?.data?.status === "valid") {
            // console.log("response", response.data);
            toast.success(response["data"]["message"]);
            // navigate("/chiller");
            navigate(path, { state: { project_id: projectID } });

          } else {
            navigate("/projectinformation");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error("Something error occured..!, Please try again.");
          console.log(error);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Userdashsidebar currentPage={"dashboard"} />
          <div className="content-page">
            <div className="content">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <div className="card transparent">
                      <div className="card-body pt-1">
                        <div className="row justify-content-between">
                          <div
                            className={
                              allstats.user_has_package === 0
                                ? "col-lg-6"
                                : "col-lg-9"
                            }
                          >
                            <div className="page-title-box">
                              <h4 className="page-title pb-0">
                                Welcome To Dashboard {process.env.APP_MODE}
                              </h4>
                            </div>
                          </div>

                          {/* <DownloadButton  url='http://43.205.20.110:5000/static/uploads/user_manual/sample.pdf' /> */}
                          {allstats.user_has_package > 0 && (
                            <>
                              <div className="col-lg-2 text-right align-self-center">
                                <Link
                                  to="/projectinformation"
                                  path="relative"
                                  className="btn btn-sm btn-success mt-2"
                                  onClick={() => {
                                    localStorage.setItem("project_id", "");
                                    localStorage.setItem(
                                      "package_transaction_id",
                                      ""
                                    );
                                  }}
                                >
                                  <i className="fe-plus-circle"></i> Create
                                  Project
                                </Link>
                              </div>
                            </>
                          )}
                          {allstats.user_has_package === 0 && (
                            <>
                              <div className="col-lg-6 text-right align-self-center text-white">
                                <b>
                                  Please wait until we activate your
                                  subscription packages{" "}
                                </b>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <p className="text-white">
                              Last Login Date & Time :{formattedDate}
                            </p>
                            {/* <pre style={{ color: "#fff" }}>
                                                    {JSON.stringify(userDetails, null, 2)}
                                                </pre> */}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="card widget-box-two bg-success border-0">
                              <div className="card-body">
                                <div className="media">
                                  <div className="media-body wigdet-two-content">
                                    <p
                                      className="m-0 text-uppercase text-white"
                                      title="Statistics"
                                    >
                                      Total Projects
                                    </p>
                                    <h2 className="text-white">
                                      <span data-plugin="counterup">
                                        {allstats.all_projects}
                                      </span>
                                      <i className="mdi mdi-arrow-up text-white font-22"></i>
                                    </h2>
                                  </div>
                                  <div className="avatar-lg rounded-circle bg-soft-light ml-2 align-self-center">
                                    <i className="fas fa-building font-22 avatar-title text-white"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4  col-md-6 col-sm-6">
                            <div className="card widget-box-two bg-warning border-0">
                              <div className="card-body">
                                <div className="media">
                                  <div className="media-body wigdet-two-content">
                                    <p
                                      className="m-0 text-uppercase text-white"
                                      title="Statistics"
                                    >
                                      Pending Projects
                                    </p>
                                    <h2 className="text-white">
                                      <span data-plugin="counterup">
                                        {allstats.pending_projects}
                                      </span>
                                      <i className="mdi mdi-arrow-up text-white font-22"></i>
                                    </h2>
                                  </div>
                                  <div className="avatar-lg rounded-circle bg-soft-light ml-2 align-self-center">
                                    <i className="fas fa-building font-22 avatar-title text-white"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="card widget-box-two bg-danger border-0">
                              <div className="card-body">
                                <div className="media">
                                  <div className="media-body wigdet-two-content">
                                    <p
                                      className="m-0 text-uppercase text-white"
                                      title="Statistics"
                                    >
                                      Help &amp; Support Requests
                                    </p>
                                    <h2 className="text-white">
                                      <span data-plugin="counterup">
                                        {allstats.support_requests}
                                      </span>
                                      <i className="mdi mdi-arrow-up text-white font-22"></i>
                                    </h2>
                                  </div>
                                  <div className="avatar-lg rounded-circle bg-soft-light ml-2 align-self-center">
                                    <i className="fas fa-comment font-22 avatar-title text-white"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row profilepg">
                          <div className="col-lg-12">
                            <div className="card border-0">
                              <div className="card-header bg-success">
                                <h4 className="card-title text-white mb-0">
                                  Recent Projects
                                </h4>
                              </div>
                              <div className="card-body">
                                <div className="table-responsive">
                                  <table className="table ">
                                    <thead>
                                      <tr>
                                        <th>S.No.</th>
                                        <th>Date</th>
                                        <th> Project ID</th>
                                        <th>Project Name</th>
                                        <th>Plant Size (TR)</th>
                                        <th>Phase </th>
                                        <th>Status </th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {allProjectsList &&
                                        allProjectsList.map((app) => (
                                          <tr
                                            key={allProjectsList.indexOf(app)}
                                          >
                                            {/* <td>{userDetails.title}</td> */}
                                            <td>
                                              {allProjectsList.indexOf(app) + 1}
                                            </td>
                                            {/* <td>{app.project_date}</td> */}
                                            <td>
                                              {new Date(
                                                app.project_date
                                              ).toLocaleString()}
                                            </td>
                                            <td>{app.gsas_project_id}</td>
                                            <td>{app.project_name || "-"}</td>
                                            <td>{app.plant_size || "-"}</td>
                                            <td>{app.project_phase || "-"}</td>
                                            <td className="text-uppercase text-center">
                                              <span
                                                className={
                                                  app.project_status ===
                                                  "completed"
                                                    ? "badge bg-success"
                                                    : "badge bg-warning"
                                                }
                                              >
                                                {app.project_status}
                                              </span>
                                            </td>
                                            <td className="text-center">
                                              {app.project_status ===
                                              "completed" && (
                                                <Link
                                                  to={
                                                    "/final-result?s=" +
                                                    app.project_status +
                                                    "&project_id=" +
                                                    app._id.$oid
                                                  }
                                                  className="btn btn-sm btn-info"
                                                >
                                                  View Result
                                                </Link>
                                              )}

                                              {app.project_status === "completed" &&
                                              <button
                                              onClick={() =>
                                                updateProjectStatusPending(
                                                  app.project_phase,
                                                  app._id.$oid
                                                )
                                              }
                                              className="btn btn-sm btn-default"
                                            >
                                              Edit
                                            </button>
                                              
                                            }


{app.project_status === "pending" &&
                                              <button
                                              onClick={() =>
                                                editProjectProgram(
                                                  app.project_phase,
                                                  app._id.$oid
                                                )
                                              }
                                              className="btn btn-sm btn-default"
                                            >
                                              Edit
                                            </button>
                                              
                                            }
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                                {/* DSD
                                <pre style={{ color: "#fff" }}>
                                  {JSON.stringify(userDetails, null, 2)}
                                </pre> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboardinfo;
