import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Dashboardsidebar from "./Dashboardsidebar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import "../../css/background_image.css";
import {
  projectDetails,
  projectSteponeURL,
  jsonLoadUrl,
  citiesListURL,
  countriesListURL,
  paymentTransactionListUrl,
  paymentTransactionDetailsUrl,
  uploadJSONUrl,
} from "../../globalURL";
import Spinner from "./Spinner";
import FormCustomInput from "../../common/form_input_custom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getProjectLocalId,
  getAccessTokenLocal,
  getCurrentPackageSelected,
} from "../../common/common_helper";

const Projectinformation = ({ currentPage }) => {
  const [isReadonlyEnable, setIsReadonlyEnable] = useState(false);
  const dataFetchedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [jsonUploadEnable, setJsonUploadEnable] = useState(false);
  const [usermanualdata, setUsermanualdata] = useState({});

  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [selectPackage, setSelectPackage] = useState(true);
  const [mySubscriptions, setMySubscriptions] = useState([]);

  const { state } = useLocation();
  const {
    gsas_project_id,
    project_phase,
    project_date,
    project_name,
    client_name,
    consultant_name,
    plant_size,
  } = state || {};
  

  const changeJsonEnableStatusFun = () => {
    setJsonUploadEnable(!jsonUploadEnable);
  };

  const [formData, setFormData] = useState({
    project_date: new Date(),
    package_transaction_id: getCurrentPackageSelected(),
    gsas_project_id: gsas_project_id || "",
    country_id: "Doha",
    city_id: "Qatar",
    selected_units: "kW",
    project_name: project_name || "",
    client_name: client_name || "",
    consultant_name: consultant_name || "",
    plant_size: plant_size || "",
    project_phase: "Design Phase",
    access_token: localStorage.getItem("access_token"),
  });



  const [citiesList, setCitiesList] = useState([]);
  const fetchCities = async (id) => {
    await axios
      .post(citiesListURL, JSON.stringify({ country_id: id, state_id: "" }), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setCitiesList(res["data"]["data"]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [formDataErrors, setFormDataErrors] = useState({
    project_date: "",
    country_id: "",
    city_id: "",
    gsas_project_id: "",
    project_name: "",
    client_name: "",
    consultant_name: "",
    plant_size: "",
    project_phase: "Design Phase",
    access_token: localStorage.getItem("access_token"),
  });

  const handleDateChange = (date) => {
    if (date instanceof Date) {
      const formattedDate = date.toLocaleDateString("en-GB");
      setFormData({
        ...formData,
        project_date: formattedDate,
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (formDataErrors[name] && value) {
      setFormDataErrors({ ...formDataErrors, [name]: "" });
    }
  };

  const validateFormData = () => {
    const errors = {};
    if (!formData.project_name)
      errors.project_name = "Please enter project name";
    if (!formData.project_date)
      errors.project_date = "Please enter project date";
    if (!formData.country_id) errors.country_id = "Please Select Country";
    if (!formData.city_id) errors.city_id = "Please Select Location";
    if (!formData.plant_size) errors.plant_size = "Please enter plant size";
    return errors;
  };

  const [selectedJsonFile, setSelectedJsonFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedJsonFile(event.target.files[0]);
  };
  const submitJsonFileUpload = async (e) => {
    e.preventDefault();

    try {
      const formDataJson = new FormData();
      formDataJson.append("file", selectedJsonFile);
      formDataJson.append("access_token", localStorage.getItem("access_token"));
      formDataJson.append(
        "package_transaction_id",
        localStorage.getItem("package_transaction_id")
      );

      await fetch(jsonLoadUrl, {
        method: "POST",
        body: formDataJson,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "valid") {
            toast.success(data.message);
            localStorage.setItem("project_id", data.id);
            localStorage.setItem("phase", data.phase);
            const path =
              data.phase === "As Operated" || data.phase === "Operations Phase"
                ? "/operational"
                : "/chiller";
            navigate(path, { state: { project_id: data.id } });
          } else {
            toast.error(data.message);
            // alert(data.message);
          }
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while submitting the form");
    }
  };

  useEffect(() => {
    if(!formData.gsas_project_id){
      formData.gsas_project_id = localStorage.getItem(
        "gsas_project_id"
      ); 
    }
  }, [formData.gsas_project_id])


  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateFormData();
    if (Object.keys(errors).length > 0) {
      setFormDataErrors(errors);
      setSubmitted(false);
      return;
    }
    formData.package_transaction_id = localStorage.getItem(
      "package_transaction_id"
    ); 
 
    setIsLoading(true);
    setSubmitted(true);
    try {
     
        const response = await axios.post(
          projectSteponeURL,
          JSON.stringify(formData),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // console.log("response", response);
        setIsLoading(false);

        if(formData.gsas_project_id !== ""){
          if (response?.data?.data?.id) {
            toast.success("Project information submitted successfully!");
            const { id } = response?.data?.data;
            const {
              gsas_project_id,
              project_phase,
              project_date,
              project_name,
              client_name,
              consultant_name,
              plant_size,
            } = formData;
  
            localStorage.setItem("project_id", id);
            localStorage.setItem("gsas_project_id", gsas_project_id);
            localStorage.setItem("phase", project_phase);
            localStorage.setItem("project_date", project_date);
            localStorage.setItem("project_name", project_name);
            localStorage.setItem("client_name", client_name);
            localStorage.setItem("consultant_name", consultant_name);
            localStorage.setItem("plant_size", plant_size);
  
            const path =
              project_phase === "As Operated" ||
                project_phase === "Operations Phase"
                ? "/operational"
                : "/chiller";
           
            navigate(path, { state: { project_id: id } });
         
          } else {
            // alert(`${response.data.message}`);
            toast.error(response.data.message);
          }
        }else{
          toast.error("please subscribe any package to continue")
        }
      // }
    } catch (error) {  
      setSubmitted(false);
      setIsLoading(false);
      toast.error("Something error occured..!, Please try again.");
      console.error("Error:", error);
    }

  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);

    axios
      .post(uploadJSONUrl, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        // console.log("json", res?.data?.data);
        setUsermanualdata(res?.data?.data);
      });

    if (localStorage.getItem("project_id") !== "") {
      axios
        .post(
          projectDetails,
          JSON.stringify({ project_id: localStorage.getItem("project_id") }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          let result = res?.data?.data;
          // console.log("result", result);
          if (res.data.data == null) {
          } else {
            localStorage.setItem("phase", res.data.data.project_phase);
            disableSubmitButton();
            fetchCities(res.data.data.country_id);
            setFormData(res?.data?.data);
            if (res?.data?.data.project_status === "completed") {
              setIsReadonlyEnable(true);
            } else {
              setIsReadonlyEnable(false);
            }
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          // console.log(error);
        });
    }

    axios
      .post(countriesListURL, JSON.stringify({ status: 1 }), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setCountriesList(res["data"]["countires"]);
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
    fetchRequests();
    if (getCurrentPackageSelected()) {
      setSelectPackage(false);
    }
    fetchPakDetails();

    if (getCurrentPackageSelected() === "") {
      setSelectPackage(true);
    } else {
      setSelectPackage(false);
    }
  }, []);

  const fetchPakDetails = () => {
    if (getCurrentPackageSelected()) {
      axios
        .post(
          paymentTransactionDetailsUrl,
          JSON.stringify({ id: getCurrentPackageSelected() }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          // console.log(res.data.data);
          let result = res.data.data;
          // console.log("Result ::::: ", result);
          // alert(result.thermal_store_enable)
          if (result) {
            setCurrentPackage(result);
          }
          setIsLoading(false);
        })

        .catch((error) => {
          console.log(error);
        });
    }

  };

  const fetchRequests = async () => {
    try {
      const response = await axios.post(
        paymentTransactionListUrl,
        JSON.stringify({ access_token: getAccessTokenLocal() }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("response", response);
      setIsLoading(false);
      if (response?.data?.status === "valid") {
        // console.log("response", response.data.data[0]["_id"]);
        if (response.data.data.length === 1) {
          selectPackageNow(
            response.data.data[0],
            response.data.data[0]["package_title"],
            response.data.data[0]["_id"],
            response.data.data[0]["new_gsas_project_id"],
          );
        }
        // selectPackageNow()
        // setFormData(response.data.data)
        setMySubscriptions(response.data.data);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while submitting the form");
      console.log(error);
      // alert("An error occurred while submitting the form.");
    }
  };
  const [currentPackage, setCurrentPackage] = useState({});
  const selectPackageNow = (item, pkgName, pkgId,newGsasId) => { 
    // alert("Your Selected '"+ pkgName +"' Package "); 
    setCurrentPackage(item);
    // setCurrentPackage(JSON.stringify(item));
    toast.warning("Your '" + pkgName + "' Package is Activated ");
    localStorage.setItem("package_transaction_id", pkgId);
    // localStorage.setItem("new_gsas_project_id", newGsasId);  
    setFormData({ ...formData, package_transaction_id: pkgId });
    setFormData({ ...formData, gsas_project_id: newGsasId });
    setSelectPackage(false);
  };
  const [disableButton, setDisableButton] = useState(true);
  const disableSubmitButton = (e) => {
    // alert(currentPackage)
    // console.log(currentPackage.tr_gtn_or_ltn_gtne_or_ltne)
    // alert(e.target.value);
    if (currentPackage.tr_required === "Yes") {
      // console.log(currentPackage.tr)
      let inputPlantTr = e.target.value; //10000
      let allowedTr = currentPackage.tr; //50,000
      // alert(allowedTr)
      // > --- Greater THan  |  < --- lessthan
      if (currentPackage.tr_gtn_or_ltn_gtne_or_ltne === "<") {
        if (allowedTr < inputPlantTr) {
          setDisableButton(true);
        } else{
          setDisableButton(false);
        }
      } else if(currentPackage.tr_gtn_or_ltn_gtne_or_ltne === ">"){
        if (allowedTr > inputPlantTr) {
          setDisableButton(false);
        }else{
          setDisableButton(true);
        }
      }else if (currentPackage.tr_gtn_or_ltn_gtne_or_ltne === ">=") {
        if (allowedTr >= inputPlantTr) {
          setDisableButton(false);
        }else{
          setDisableButton(true);
        }
      } else if (currentPackage.tr_gtn_or_ltn_gtne_or_ltne === "<=") {
        if (allowedTr <= inputPlantTr) {
          setDisableButton(true);
        }else{
          setDisableButton(false);
        }
      }
    }else{
      setDisableButton(false)
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Dashboardsidebar currentPage="projectinformation" />

          <div className="content-page mt-0">
            <div className="content">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  {selectPackage ? (
                    <>
                      <div className="col-lg-10 col-md-12">
                        <div className="card transparent">
                          <div className="card-body pt-1">
                            <div className="row justify-content-center">
                              <div className="col-lg-12">
                                <div className="page-title-box">
                                  <h4 className="page-title">
                                    Choose Your Package
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-md-12 mb-2">
                              <table className="table table-dark table-striped table-hover">
                                <thead className="text-center">
                                  <tr>
                                    <th>
                                      <b> S.no </b>
                                    </th>
                                    <th>
                                      <b> Package Name </b>
                                    </th>
                                    <th>
                                      <b> Package Name </b>
                                    </th>
                                    <th>
                                      <b> Enabled Watercool </b>
                                    </th>
                                    <th>
                                      <b> Enabled Aircool </b>
                                    </th>
                                    <th>
                                      <b> Enabled TES </b>
                                    </th>
                                    <th>
                                      <b> Total Projects </b>
                                    </th>
                                    <th>
                                      <b> Remain Projects </b>
                                    </th>
                                    <th>
                                      <b> Action </b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {mySubscriptions &&
                                    mySubscriptions.map((item, index) =>
                                      item.payment_status === "Completed" &&
                                        item.is_expired === "No" &&
                                        item.no_of_projects_remain > 0 ? (
                                        <>
                                          <tr className="text-center">
                                            <td>{index + 1}</td>
                                            <td> {item.package_title}</td>
                                            <td> {item.package_desc}</td>
                                            <td>
                                              
                                              {item.water_cooled_chiller_enable}
                                            </td>
                                            <td>
                                              
                                              {item.air_cooled_chiller_enable}
                                            </td>
                                            <td>
                                              
                                              {item.thermal_store_enable}
                                            </td>
                                            <td>
                                              
                                              {item.no_of_projects_allow}
                                            </td>
                                            <td>
                                              
                                              {item.no_of_projects_remain}
                                            </td>
                                            <td>
                                              <button
                                                className="btn btn-sm btn-success"
                                                onClick={() =>
                                                  selectPackageNow(
                                                    item,
                                                    item.package_title,
                                                    item._id,
                                                    item.new_gsas_project_id
                                                  )
                                                }
                                              >
                                                
                                                Select
                                              </button>
                                            </td>
                                          </tr>
                                        </>
                                      ) : (
                                        <></>
                                      )
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="col-lg-10 col-md-12">
                      <div className="card transparent">
                        <div className="card-body pt-1">
                          <div className="row justify-content-center">
                            <div className="col-lg-6">
                              <div className="page-title-box">
                                <h4 className="page-title">
                                  Project Information
                                  {/* <pre style={{ color: "#fff" }}>
                                    {JSON.stringify(currentPackage, null, 2)}
                                  </pre> */}
                                </h4>
                              </div>
                            </div>
                            <div className="col-lg-6 text-right">
                              {usermanualdata?.enable_project_json_upload ===
                                "yes" && (
                                  <button
                                    type="button"
                                    onClick={changeJsonEnableStatusFun}
                                    className="btn btn-link "
                                  >
                                    <span
                                      style={{
                                        color: "#fff",
                                        borderRadius: "30px",
                                        background: "green",
                                        padding: "10px",
                                      }}
                                    >
                                      Upload Project Input File (*.json)
                                    </span>
                                  </button>
                                )}

                              {/* {getProjectLocalId() === "" && <>
               </>} */}
                            </div>
                          </div>
                          {getProjectLocalId() === "" ? "" : ""}
                          {jsonUploadEnable === true ? (
                            <>
                              <form
                                method="post"
                                action="#"
                                onSubmit={submitJsonFileUpload}
                              >
                                <div className="row">
                                  <div className="col-lg-12 col-md-12 mb-2">
                                    <label className="colyellow">
                                      Json File Upload
                                    </label>
                                    <div className="form-floating mb-0">
                                      <input
                                        type="file"
                                        accept="application/JSON"
                                        className="filestyle form-control py-1 px-2"
                                        onChange={handleFileChange}
                                      />
                                    </div>
                                    <div className="form-floating mb-0">
                                      <br />
                                      <button
                                        type="submit"
                                        className="btn btn-primary mr-1 waves-effect waves-light"
                                      >
                                        Load Json Data
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </>
                          ) : (
                            <>
                              <form
                                action="#"
                                method="post"
                                onSubmit={handleSubmit}
                              >
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <DatePicker
                                        name="project_date"
                                        data-provide="datepicker"
                                        minDate={new Date()}
                                        placeholder="DD/MM/YYYY"
                                        dateFormat="dd/MM/yyyy"
                                        value={formData.project_date}
                                        className="customdatepicker"
                                        selected={
                                          formData.project_date instanceof Date
                                            ? formData.project_date
                                            : null
                                        }
                                        onChange={handleDateChange}
                                      />
                                      {formDataErrors.project_date && (
                                        <div className="text-danger">
                                          {formDataErrors.project_date}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                          marginLeft: "5px",
                                        }}
                                        htmlFor=""
                                      >
                                        Select Country
                                      </label>
                                      <div className="form-floating mb-2">
                                        <select
                                          disabled={isReadonlyEnable}
                                          onChange={(event) => {
                                            setFormData({
                                              ...formData,
                                              ["country_id"]:
                                                event.target.value,
                                            });
                                            fetchCities(event.target.value);
                                            // getProjectId(event.target.value);
                                          }}
                                          name="country_id"
                                          id=""
                                          className="form-select w-100"
                                          defaultValue={formData.country_id}
                                          value={formData.country_id}
                                        >
                                          <option value="">
                                            -------- Please Select Country --------
                                          </option>
                                          {countriesList &&
                                            countriesList.map((app) => (
                                              <option
                                                key={app}
                                                value={app}
                                                selected={formData.country_id === app}
                                              >
                                                {app}
                                              </option>
                                            ))}
                                        </select>
                                        {formDataErrors.country_id && (
                                          <div className="text-danger">
                                            {formDataErrors.country_id}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                          marginLeft: "5px",
                                        }}
                                        htmlFor=""
                                      >
                                        Select City
                                      </label>
                                      <div className="form-floating mb-2">
                                        <select
                                          disabled={isReadonlyEnable}
                                          onChange={(event) => {
                                            setFormData({
                                              ...formData,
                                              ["city_id"]: event.target.value,
                                            });

                                          }}
                                          name="city_id"
                                          id=""
                                          value={formData.city_id.toUpperCase()}
                                          defaultValue={formData.city_id.toUpperCase()}
                                          className="form-select w-100"
                                        >
                                          <option value="">-------- Please Select City --------</option>
                                          {citiesList &&
                                            citiesList.map((app) => (
                                              <option key={app} value={app}>
                                                {app}
                                              </option>
                                            ))}
                                        </select>
                                        {formDataErrors.city_id && (
                                          <div className="text-danger">
                                            {formDataErrors.city_id}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-floating mb-2">
                                      <input
                                        type="text"
                                        value={formData.gsas_project_id}
                                        onChange={handleInputChange}
                                        name="gsas_project_id"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="Ex.1144"
                                        readOnly={true}
                                      />
                                      <label htmlFor="floatingInput">
                                        New Project ID
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-floating mb-2">
                                      <input
                                        value={formData.project_name}
                                        onChange={handleInputChange}
                                        name="project_name"
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="Project Name"
                                        readOnly={isReadonlyEnable}
                                      />
                                      <label htmlFor="floatingInput">
                                        Project Name
                                      </label>
                                      {formDataErrors.project_name && (
                                        <div className="text-danger">
                                          {formDataErrors.project_name}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-floating mb-2">
                                      <input
                                        value={formData.client_name}
                                        onChange={handleInputChange}
                                        name="client_name"
                                        readOnly={isReadonlyEnable}
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="Client Name"
                                      />
                                      <label htmlFor="floatingInput">
                                        Client Name
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-floating mb-2">
                                      <input
                                        readOnly={isReadonlyEnable}
                                        value={formData.consultant_name}
                                        onChange={handleInputChange}
                                        name="consultant_name"
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="Consultant"
                                      />
                                      <label htmlFor="floatingInput">
                                        Consultant Name
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label
                                      style={{
                                        color: "#FFF",
                                        fontSize: "12px",
                                        marginLeft: "5px",
                                      }}
                                      htmlFor=""
                                    >
                                      Select Units
                                    </label>
                                  </div>
                                  <div className="col-lg-6 col-md-6 position-relative">
                                    <div className="form-floating mb-2">
                                      <select
                                        defaultValue={formData.selected_units}
                                        name="selected_units"
                                        value={formData.selected_units}
                                        className="form-select w-100"
                                        onChange={handleInputChange}
                                      >                                            
                                        <option value="">
                                          -------- Units --------
                                        </option>
                                        <option
                                          value="W"
                                          selected={formData.selected_units === "W"}
                                        >
                                          Watts (W)
                                        </option>
                                        <option
                                          value="kW"
                                          selected={formData.selected_units === "kW"}
                                        >
                                          Kilo Watts (kW)
                                        </option>

                                        <option
                                          value="MW"
                                          selected={formData.selected_units === "MW"}
                                        >
                                          Mega Watts (MW)
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className="col-lg-6 col-md-6 position-relative">
                                    <div className="form-floating mb-2">
                                      <FormCustomInput
                                        readOnly={isReadonlyEnable}
                                        onChangeEvent={handleInputChange}
                                        onkeyup={disableSubmitButton}
                                        isNumField={true}
                                        value={formData.plant_size}
                                        name="plant_size"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="plant size"
                                      />
                                      <label htmlFor="floatingInput">
                                        Plant Size (in TR)
                                      </label>

                                      {formDataErrors.plant_size && (
                                        <div className="text-danger">
                                          {formDataErrors.plant_size}
                                        </div>
                                      )}
                                    </div>
                                    <span className="tontxt">Tons</span>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="form-group">
                                      <label className="col-form-label yellowcol">
                                        Select Option
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-3">
                                    <Link
                                      onClick={() =>
                                        setFormData({
                                          ...formData,
                                          ["project_phase"]: "Design Phase",
                                        })
                                      }
                                      className={
                                        formData.project_phase ===
                                          "Design Phase"
                                          ? "checkactive"
                                          : ""
                                      }
                                    >
                                      <div
                                        className={
                                          formData.project_phase ===
                                            "Design Phase"
                                            ? "desoprbox active"
                                            : "desoprbox orange"
                                        }
                                      >
                                        <i className="fe-box fa-lg"></i>
                                        <p>Design Phase</p>
                                      </div>
                                    </Link>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-3">
                                    <Link
                                      onClick={() =>
                                        setFormData({
                                          ...formData,
                                          ["project_phase"]: "As Operated",
                                        })
                                      }
                                      name="project_phase"
                                      className={
                                        formData.project_phase === "As Operated"
                                          ? "checkactive"
                                          : ""
                                      }
                                    >
                                      <div
                                        className={
                                          formData.project_phase ===
                                            "As Operated"
                                            ? "desoprbox active"
                                            : "desoprbox orange"
                                        }
                                      >
                                        <i className="fe-bar-chart fa-lg"></i>
                                        <p>As Operated Phase</p>
                                      </div>
                                    </Link>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-3">
                                    <Link
                                      onClick={() =>
                                        setFormData({
                                          ...formData,
                                          ["project_phase"]:
                                            "Design and As Operated",
                                        })
                                      }
                                      className={
                                        formData.project_phase ===
                                          "Design and As Operated"
                                          ? "checkactive"
                                          : ""
                                      }
                                    >
                                      <div
                                        className={
                                          formData.project_phase ===
                                            "Design and As Operated"
                                            ? "desoprbox active"
                                            : "desoprbox red"
                                        }
                                      >
                                        <i className="fe-briefcase fa-lg"></i>
                                        <p>Design and As Operated Phase</p>
                                      </div>
                                    </Link>
                                  </div>
                                </div>

                                <div className="form-group row justify-content-end mt-3">
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-left prj">
                                    <button
                                      type="submit"
                                      onClick={() => navigate("/dashboard")}
                                      className="btn btn-pryellow mr-1 waves-effect waves-light"
                                    >
                                      Go to dashboard
                                    </button>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-right prj">
                                    <button
                                      disabled={disableButton}
                                      type="submit"
                                      className="btn btn-pryellow mr-1 waves-effect waves-light"
                                    >
                                      Go to Next
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Projectinformation;
