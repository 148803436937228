import React, { useEffect, useRef, useState } from "react";
import Dashboardsidebar from "./Dashboardsidebar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../../css/background_image.css";
import {
  thermalStoreTwoUrl,
  projectDetails,
  uploadFileOrImage,
  paymentTransactionDetailsUrl,
  BaseURL,
} from "../../globalURL";
import axios from "axios";
import Spinner from "./Spinner";
import ThermalProfile from "./ThermalProfile";

import TSChangingProfile from "../../src/ts-charging-profile-template.csv";
import { toast } from "react-toastify";
import FileUpload, {
  DocumentFileUpload,
} from "../dashboard_pages/otherdocumentfile";
import { getCurrentPackageSelected, getProjectLocalId } from "../../common/common_helper";



const Thermalstore = () => {
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const [showTable, setShowTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [thermalStoreEnable, setthermalStoreEnable] = useState(false);

  const [formData, setFormData] = useState({
    is_thermal_store_available: "No",
    thermal_store_capacity_kwh: "",
    lower_allowance_capacity_in_percentage: "",
    charging_capacity_kwh_per_hr_maximum: "",
    charging_capacity_kwh_per_hr_minimum: "",
    thermal_store_profile_file: "",
    tes_other_document_file: "",
    maximum_daily_thershold: "",
    charging_cycles: "",
    losses_per_day: "",
    tes_pumps: "",
    tes_pumps_type: "",
    ts_pump_load_100: "",
    ts_pump_load_25: "",
    ts_pump_load_50: "",
    ts_pump_load_75: "",
    ts_pump_power_input_100: "",
    ts_pump_power_input_25: "",
    ts_pump_power_input_50: "",
    ts_pump_power_input_75: "",
    project_id: localStorage.getItem("project_id"),
    access_token: localStorage.getItem("access_token"),
    thermal_store_profile: [
      {
        id: 1,
        daily_charging_cycle_time: "01:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 2,
        daily_charging_cycle_time: "02:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 3,
        daily_charging_cycle_time: "03:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 4,
        daily_charging_cycle_time: "04:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 5,
        daily_charging_cycle_time: "05:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 6,
        daily_charging_cycle_time: "06:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 7,
        daily_charging_cycle_time: "07:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 8,
        daily_charging_cycle_time: "08:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 9,
        daily_charging_cycle_time: "09:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 10,
        daily_charging_cycle_time: "10:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 11,
        daily_charging_cycle_time: "11:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 12,
        daily_charging_cycle_time: "12:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 13,
        daily_charging_cycle_time: "13:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 14,
        daily_charging_cycle_time: "14:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 15,
        daily_charging_cycle_time: "15:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 16,
        daily_charging_cycle_time: "16:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 17,
        daily_charging_cycle_time: "17:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 18,
        daily_charging_cycle_time: "18:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 19,
        daily_charging_cycle_time: "19:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 20,
        daily_charging_cycle_time: "20:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 21,
        daily_charging_cycle_time: "21:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 22,
        daily_charging_cycle_time: "22:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 23,
        daily_charging_cycle_time: "23:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 24,
        daily_charging_cycle_time: "24:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
    ],
  });

  const handleChargeChange = (event, id) => {
    const { value } = event.target;
    // alert(id,value)

    setFormData((prevState) => {
      if (!prevState || !prevState.thermal_store_profile) {
        return prevState;
      }
      return {
        ...prevState,
        thermal_store_profile: prevState.thermal_store_profile.map((item) => {
          if (item.id === id) {
            return { ...item, daily_charging_cycle_charge: value };
          } else {
            return item;
          }
        }),
      };
    });

    // console.log("charge", value);
  };


  function handleFileUpload(event) {
    const formDataImage = new FormData();
    formDataImage.append("file", event.target.files[0]);
    fetch(uploadFileOrImage, {
      method: "POST",
      body: formDataImage,
    })
      .then((r) => r.json())
      .then((data) => {
        // console.log("sss",data["data"]["full_file_address"]);
        setFormData({
          ...formData,
          ["tes_other_document_file"]: data["data"]["full_file_address"],
        });
      });

    // console.log("fileDocName", fileDocName);
  }
  

  const handleDischargeChange = (event, id) => {
    const { value } = event.target;
    // alert(id+"  : "+value)
    setFormData((prevState) => {
      if (!prevState || !prevState.thermal_store_profile) {
        return prevState;
      }
      return {
        ...prevState,
        thermal_store_profile: prevState.thermal_store_profile.map((item) => {
          if (item.id === id) {
            return { ...item, daily_charging_cycle_discharge: value };
          } else {
            return item;
          }
        }),
      };
    });
  };

  const coolingLoadingProfileUpload = (event) => {
    //formData.thermal_store_profile_file
    const formDataImage = new FormData();
    // setSelectedFile(event.target.files[0])
    formDataImage.append("file", event.target.files[0]);
    fetch(uploadFileOrImage, {
      method: "POST",
      body: formDataImage,
    })
      .then((r) => r.json())
      .then((data) => {
        // console.log(data["data"]["full_file_address"]);
        setFormData({
          ...formData,
          ["thermal_store_profile_file"]: data["data"]["full_file_address"],
        });
        // setfinalCoolingLoadingFile(data["data"]["full_file_address"])
        // formData.append({"thermal_store_profile_file":finalCoolingLoadingFile});
      });
  };

  const [formDataErrors, setFormDataErrors] = useState({
    "is_thermal_store_available": "No",
    "thermal_store_capacity_kwh": "",
    "lower_allowance_capacity_in_percentage": "",
    "charging_capacity_kwh_per_hr_maximum": "",
    "charging_capacity_kwh_per_hr_minimum": "",
    "maximum_daily_thershold": "",
    "charging_cycles": "",
    "losses_per_day": "",
    "tes_pumps": "",
    "tes_pumps_type": "",
    "ts_pump_load_100": "",
    "ts_pump_load_75": "",
    "ts_pump_load_50": "",
    "ts_pump_load_25": "",
    "ts_pump_power_input_100": "",
    "ts_pump_power_input_75": "",
    "ts_pump_power_input_50": "",
    "ts_pump_power_input_25": "",
    "thermal_store_profile": [
      {
        id: 1,
        daily_charging_cycle_time: "01:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 2,
        daily_charging_cycle_time: "02:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 3,
        daily_charging_cycle_time: "03:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 4,
        daily_charging_cycle_time: "04:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 5,
        daily_charging_cycle_time: "05:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 6,
        daily_charging_cycle_time: "06:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 7,
        daily_charging_cycle_time: "07:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 8,
        daily_charging_cycle_time: "08:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 9,
        daily_charging_cycle_time: "09:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 10,
        daily_charging_cycle_time: "10:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 11,
        daily_charging_cycle_time: "11:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 12,
        daily_charging_cycle_time: "12:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 13,
        daily_charging_cycle_time: "13:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 14,
        daily_charging_cycle_time: "14:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 15,
        daily_charging_cycle_time: "15:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 16,
        daily_charging_cycle_time: "16:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 17,
        daily_charging_cycle_time: "17:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 18,
        daily_charging_cycle_time: "18:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 19,
        daily_charging_cycle_time: "19:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 20,
        daily_charging_cycle_time: "20:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 21,
        daily_charging_cycle_time: "21:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 22,
        daily_charging_cycle_time: "22:00",
        daily_charging_cycle_charge: "No",
        daily_charging_cycle_discharge: "Yes",
      },
      {
        id: 23,
        daily_charging_cycle_time: "23:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
      {
        id: 24,
        daily_charging_cycle_time: "24:00",
        daily_charging_cycle_charge: "Yes",
        daily_charging_cycle_discharge: "No",
      },
    ],
  });


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (value === "Fixed Profile") {
      setShowTable(true);
    } else {
      setShowTable(false);
    }
    setFormData({ ...formData, [name]: value });

    if (formDataErrors[name] && value) {
      setFormDataErrors({ ...formDataErrors, [name]: "" });
    }
  };

  const validateFormData = () => {
    const errors = {};
    const requiredFields = [
      "thermal_store_capacity_kwh",
      "lower_allowance_capacity_in_percentage",
      "charging_capacity_kwh_per_hr_maximum",
      "charging_capacity_kwh_per_hr_minimum",
      "maximum_daily_thershold",
      "charging_cycles",
      "losses_per_day",
      "tes_pumps",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = `* Please enter ${field}`;
      }
    });
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const errors = validateFormData();
    if (Object.keys(errors).length > 0) {
      setFormDataErrors(errors);
      setIsLoading(false);
      return;
    }
    setFormDataErrors({});
    // return false
    if (Object.keys(errors).length === 0) {
      // console.log("ThermalstoreData", formData);

      try {
        const response = await axios.post(
          thermalStoreTwoUrl,
          JSON.stringify(formData),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setIsLoading(false);
        // setShowTable(false);
        if (response?.data?.data?.id) {
          const { id } = response?.data?.data;
          navigate("/pumps?id=" + id);
        } else {
          // alert(`${response.data.message}`);
          toast.error(response.data.message);
        }
      } catch (error) {
        setIsLoading(false);
        // alert("An error occurred while submitting the form.");
        toast.error("Something error occured..!, Please try again.");
        console.log(error);
      }
    }
  };

  const thermalProfileProps = {
    handleChargeChange,
    handleDischargeChange,
    setShowTable,
    formData,
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    axios
      .post(
        projectDetails,
        JSON.stringify({ project_id: localStorage.getItem("project_id") }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        // console.log(res.data.data);
        let result = res.data.data;
        if (result["thermal_store_profile"]) {
        } else {
          result["thermal_store_profile"] = [
            {
              id: 1,
              daily_charging_cycle_time: "01:00",
              daily_charging_cycle_charge: "Yes",
              daily_charging_cycle_discharge: "No",
            },
            {
              id: 2,
              daily_charging_cycle_time: "02:00",
              daily_charging_cycle_charge: "Yes",
              daily_charging_cycle_discharge: "No",
            },
            {
              id: 3,
              daily_charging_cycle_time: "03:00",
              daily_charging_cycle_charge: "Yes",
              daily_charging_cycle_discharge: "No",
            },
            {
              id: 4,
              daily_charging_cycle_time: "04:00",
              daily_charging_cycle_charge: "Yes",
              daily_charging_cycle_discharge: "No",
            },
            {
              id: 5,
              daily_charging_cycle_time: "05:00",
              daily_charging_cycle_charge: "Yes",
              daily_charging_cycle_discharge: "No",
            },
            {
              id: 6,
              daily_charging_cycle_time: "06:00",
              daily_charging_cycle_charge: "Yes",
              daily_charging_cycle_discharge: "No",
            },
            {
              id: 7,
              daily_charging_cycle_time: "07:00",
              daily_charging_cycle_charge: "Yes",
              daily_charging_cycle_discharge: "No",
            },
            {
              id: 8,
              daily_charging_cycle_time: "08:00",
              daily_charging_cycle_charge: "Yes",
              daily_charging_cycle_discharge: "No",
            },
            {
              id: 9,
              daily_charging_cycle_time: "09:00",
              daily_charging_cycle_charge: "Yes",
              daily_charging_cycle_discharge: "No",
            },
            {
              id: 10,
              daily_charging_cycle_time: "10:00",
              daily_charging_cycle_charge: "No",
              daily_charging_cycle_discharge: "Yes",
            },
            {
              id: 11,
              daily_charging_cycle_time: "11:00",
              daily_charging_cycle_charge: "No",
              daily_charging_cycle_discharge: "Yes",
            },
            {
              id: 12,
              daily_charging_cycle_time: "12:00",
              daily_charging_cycle_charge: "No",
              daily_charging_cycle_discharge: "Yes",
            },
            {
              id: 13,
              daily_charging_cycle_time: "13:00",
              daily_charging_cycle_charge: "No",
              daily_charging_cycle_discharge: "Yes",
            },
            {
              id: 14,
              daily_charging_cycle_time: "14:00",
              daily_charging_cycle_charge: "No",
              daily_charging_cycle_discharge: "Yes",
            },
            {
              id: 15,
              daily_charging_cycle_time: "15:00",
              daily_charging_cycle_charge: "No",
              daily_charging_cycle_discharge: "Yes",
            },
            {
              id: 16,
              daily_charging_cycle_time: "16:00",
              daily_charging_cycle_charge: "No",
              daily_charging_cycle_discharge: "Yes",
            },
            {
              id: 17,
              daily_charging_cycle_time: "17:00",
              daily_charging_cycle_charge: "No",
              daily_charging_cycle_discharge: "Yes",
            },
            {
              id: 18,
              daily_charging_cycle_time: "18:00",
              daily_charging_cycle_charge: "No",
              daily_charging_cycle_discharge: "Yes",
            },
            {
              id: 19,
              daily_charging_cycle_time: "19:00",
              daily_charging_cycle_charge: "No",
              daily_charging_cycle_discharge: "Yes",
            },
            {
              id: 20,
              daily_charging_cycle_time: "20:00",
              daily_charging_cycle_charge: "No",
              daily_charging_cycle_discharge: "Yes",
            },
            {
              id: 21,
              daily_charging_cycle_time: "21:00",
              daily_charging_cycle_charge: "No",
              daily_charging_cycle_discharge: "Yes",
            },
            {
              id: 22,
              daily_charging_cycle_time: "22:00",
              daily_charging_cycle_charge: "No",
              daily_charging_cycle_discharge: "Yes",
            },
            {
              id: 23,
              daily_charging_cycle_time: "23:00",
              daily_charging_cycle_charge: "Yes",
              daily_charging_cycle_discharge: "No",
            },
            {
              id: 24,
              daily_charging_cycle_time: "24:00",
              daily_charging_cycle_charge: "Yes",
              daily_charging_cycle_discharge: "No",
            },
          ];
        }

        setFormData(result);
        setIsLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });

    // Start new service
    axios
      .post(
        paymentTransactionDetailsUrl,
        JSON.stringify({ id: getCurrentPackageSelected() }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        // console.log(res.data.data);
        let result = res.data.data;
        // console.log("Result ::::: ", result);
        // alert(result.thermal_store_enable)
        if (result.thermal_store_enable === "No") {
          setthermalStoreEnable(false);
        } else if (result.thermal_store_enable === "Yes") {
          setthermalStoreEnable(true);
        } else {
          setthermalStoreEnable(false);
        }
        setIsLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);
  // Package details getting based on the local varible
  const noSelectedDefault = async ()=>{
    await axios.post(
      thermalStoreTwoUrl,
      JSON.stringify({
        "is_thermal_store_available":"No",
        "_id":getProjectLocalId()
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      let result = res.data.data;
      // setShowTable(false);
      if ( result.id) {
        navigate("/pumps?id=" + result.id);
      } else {
        // alert(`${response.data.message}`);
        toast.error(res.data.message);
      }
    })

    .catch((error) => {
      console.log(error);
    });
  }
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Dashboardsidebar currentPage={"thermalstore"} />
          <div className="content-page mt-0">
            <div className="content">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <div className="card transparent">
                      <div className="card-body pt-1">
                        <div className="row justify-content-center">
                          <div className="col-lg-12">
                            <div className="page-title-box">
                              <h4 className="page-title colyellow">
                                Thermal Store 
                                 {/* is_thermal_store_available:   {(formData.is_thermal_store_available)} */}
                              </h4>
                            </div>
                          </div>
                        </div>
                        {/* <pre style={{ color: "#fff" }}>
                    {JSON.stringify(formData, null, 2)}
                  </pre> */}
               
                        {thermalStoreEnable === false ? (

                          <>
                            <div className="alert alert-success text-center">
                              <h3>
                                <b>Thermal Store (TES)</b> Not Applicable For
                                This Package.
                              </h3>
                            </div>
                            <div className="row justify-content-between align-items-center prj">
                              <div className="col-lg-12 col-md-6 col-sm-6 col-6 text-left">
                                <Link
                                  to={"/chiller"}
                                  className="btn btn-pryellow mr-1 waves-effect waves-light"
                                >
                                  Return to previous step
                                </Link>
                              </div>
                              <div className="col-lg-12 col-md-6 col-sm-6 col-6 text-right">
                                <Link
                                  className="btn btn-pryellow mr-1 waves-effect waves-light"
                                  to="/pumps"
                                >
                                  Skip & Go Next
                                </Link>
                              </div>
                            </div>
                          </>
                        ) : (
                          <form
                            action="#"
                            method="post"
                            onSubmit={handleSubmit}
                          >
                            <div className="row">
                              <div className="col-lg-12">
                                <label
                                  style={{ color: "#FFFF00" }}
                                  htmlFor="is Thermal Store available?"
                                >
                                  is Thermal Store available?
                                </label>
                              </div>
                              <div className="col-lg-6 col-md-6 position-relative">
                                <div className="d-flex">
                                  <div className="radiocust">
                                    <label className="text-white">
                                      <input
                                        className="mr-1"
                                        type="radio"
                                        name="is_thermal_store_available"
                                        value="No"
                                        onChange={handleInputChange}
                                        checked={formData.is_thermal_store_available === "No" || formData.is_thermal_store_available === "No, switch-off" || !formData.is_thermal_store_available }
                                      />
                                      No
                                    </label>
                                  </div> 
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  <div className="radiocust">
                                    <label className="text-white">
                                    {/* ddf  {formData.is_thermal_store_available} */}
                                      <input
                                        className="mr-1"
                                        type="radio"
                                        name="is_thermal_store_available"
                                        value="Yes"
                                        onChange={handleInputChange}
                                        checked={ formData.is_thermal_store_available ===  "Yes" || formData.is_thermal_store_available === "Yes, by-pass"  }
                                      />
                                      Yes
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br />
                            <br />
                            {formData.is_thermal_store_available === "No"  || !formData.is_thermal_store_available ? (
                              <div className="row justify-content-between align-items-center prj">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-left">
                                  <Link
                                    to={"/chiller"}
                                    className="btn btn-pryellow mr-1 waves-effect waves-light"
                                  >
                                    Return to previous step
                                  </Link>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-right">
                                  <button onClick={()=>noSelectedDefault()} className="btn btn-pryellow mr-1 waves-effect waves-light">
                                  Go to Next page
                                  </button>
                                  {/* <Link
                                    className="btn btn-pryellow mr-1 waves-effect waves-light"
                                    to="/pumps"
                                  >
                                    Go to Next page
                                  </Link> */}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div
                              style={{
                                display:
                                  formData.is_thermal_store_available === "Yes"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-6 col-md-6">
                                  <div className="form-floating mb-2">
                                    <input
                                      value={
                                        formData.thermal_store_capacity_kwh
                                      }
                                      onChange={handleInputChange}
                                      name="thermal_store_capacity_kwh"
                                      type="number"
                                      pattern="[0-9]*"
                                      className="form-control"
                                      id="floatingInput"
                                      placeholder="Ex.1144"
                                    />
                                    <label htmlFor="floatingInput">
                                      {/* Capacity (kWh) */}
                                      {`Capacity (${formData?.selected_units})`}

                                      {/* Capacity (kWh) */}
                                    </label>
                                    {formDataErrors.thermal_store_capacity_kwh && (
                                      <div className="text-danger">
                                        {
                                          formDataErrors.thermal_store_capacity_kwh
                                        }
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                  <div className="form-floating mb-2">
                                    <input
                                      value={
                                        formData.lower_allowance_capacity_in_percentage
                                      }
                                      onChange={handleInputChange}
                                      name="lower_allowance_capacity_in_percentage"
                                      type="number"
                                      pattern="[0-9]*"
                                      className="form-control"
                                      id="floatingInput"
                                      placeholder="Ex.1144"
                                    />
                                    <label htmlFor="floatingInput">
                                      Lower Allowable Capacty (%)
                                    </label>
                                    {formDataErrors.lower_allowance_capacity_in_percentage && (
                                      <div className="text-danger">
                                        {
                                          formDataErrors.lower_allowance_capacity_in_percentage
                                        }
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <label
                                    style={{ color: "#FFFF00" }}
                                    htmlFor=""
                                  >
                                    {/* Charging Capacity (kWh/hr) */}
                                    {`Charging Capacity (${formData?.selected_units}/hr)`}
                                  </label>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12 col-md-6">
                                  <div className="form-floating mb-2">
                                    <input
                                      value={
                                        formData.charging_capacity_kwh_per_hr_maximum
                                      }
                                      onChange={handleInputChange}
                                      name="charging_capacity_kwh_per_hr_maximum"
                                      type="number"
                                      pattern="[0-9]*"
                                      className="form-control"
                                      id="floatingInput"
                                      placeholder="Ex.1144"
                                    />
                                    <label htmlFor="floatingInput">
                                      Maximum
                                    </label>
                                    {formDataErrors.charging_capacity_kwh_per_hr_maximum && (
                                      <div className="text-danger">
                                        {
                                          formDataErrors.charging_capacity_kwh_per_hr_maximum
                                        }
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12 col-md-6">
                                  <div className="form-floating mb-2">
                                    <input
                                      value={
                                        formData.charging_capacity_kwh_per_hr_minimum
                                      }
                                      onChange={handleInputChange}
                                      name="charging_capacity_kwh_per_hr_minimum"
                                      type="number"
                                      pattern="[0-9]*"
                                      className="form-control"
                                      id="floatingInput"
                                      placeholder="Ex.1144"
                                    />
                                    <label htmlFor="floatingInput">
                                      Minimum
                                    </label>
                                    {formDataErrors.charging_capacity_kwh_per_hr_minimum && (
                                      <div className="text-danger">
                                        {
                                          formDataErrors.charging_capacity_kwh_per_hr_minimum
                                        }
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-lg-6 col-md-6">
                                  <label
                                    style={{ fontSize: "11px", color: "white" }}
                                    htmlFor="maximum_daily_thershold"
                                  >
                                    Maximum Daily Charging Threshold
                                  </label>
                                  <div className="form-floating mb-2">
                                    <select
                                      name="maximum_daily_thershold"
                                      value={formData.maximum_daily_thershold}
                                      onChange={handleInputChange}
                                      className="form-select w-100"
                                      defaultValue={"default"}
                                    >
                                      <option value="default">
                                        Select a Threshold
                                      </option>
                                      <option
                                        value="Building Daily Peak"
                                        selected={
                                          formData.maximum_daily_thershold ===
                                          ("Building Daily Peak" ||
                                            formData.maximum_daily_thershold)
                                        }
                                      >
                                        Building Daily Peak
                                      </option>
                                      <option
                                        value="Thermal Store Peak"
                                        selected={
                                          formData.maximum_daily_thershold ===
                                          ("Thermal Store Peak" ||
                                            formData.maximum_daily_thershold)
                                        }
                                      >
                                        Thermal Store Peak
                                      </option>
                                    </select>
                                    {formDataErrors.maximum_daily_thershold && (
                                      <div className="text-danger">
                                        {formDataErrors.maximum_daily_thershold}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                  <label
                                    style={{ fontSize: "11px", color: "white" }}
                                    htmlFor="charging_cycles"
                                  >
                                    Charging Cycles
                                  </label>

                                  <div className="form-floating mb-2">
                                    <select
                                      name="charging_cycles"
                                      onChange={handleInputChange}
                                      defaultValue="Default"
                                      className="form-select w-100"
                                      value={formData.charging_cycles}
                                    >
                                      <option value="default">
                                        Select Charging Cycles
                                      </option>

                                      <option
                                        value="Fixed Profile"
                                        selected={
                                          formData.charging_cycles ||
                                          formData.charging_cycles ===
                                            "Fixed" ||
                                          formData.charging_cycles ===
                                            "Fixed Profile"
                                        }
                                      >
                                        Fixed
                                      </option>
                                      <option
                                        value="User Input"
                                        selected={
                                          formData.charging_cycles ===
                                          ("User Input" ||
                                            formData.charging_cycles)
                                        }
                                      >
                                        User Input
                                      </option>
                                    </select>
                                    {formDataErrors.charging_cycles && (
                                      <div className="text-danger">
                                        {formDataErrors.charging_cycles}
                                      </div>
                                    )}
                                  </div>

                                  {showTable ? (
                                    <ThermalProfile {...thermalProfileProps} />
                                  ) : null}
                                </div>
                              </div>

                              <div className="row my-2">
                                {formData.charging_cycles === "User Input" && (
                                  <>
                                    <div className="col-lg-6">
                                      <label className="colyellow py-1">
                                        <span className="pl-2 pb-2 mb-2 mx-2">
                                          Upload CSV File
                                        </span>
                                        <a
                                          href={TSChangingProfile}
                                          className="button btn-xs btn-success text-nowrap"
                                          download={true}
                                        >
                                          <i className="fe-download fa-lg"></i>
                                          &nbsp; TS Changing Profile Template
                                        </a>
                                      </label>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="form-floating mb-0">
                                        <input
                                          type="file"
                                          className="filestyle form-control py-1 px-2"
                                          name="thermal_store_profile_file"
                                          onChange={coolingLoadingProfileUpload}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="row">
                                <div className="col-lg-6 col-md-6">
                                  <div className="form-floating mb-2">
                                    <input
                                      value={formData.losses_per_day}
                                      onChange={handleInputChange}
                                      name="losses_per_day"
                                      type="number"
                                      pattern="[0-9]*"
                                      className="form-control"
                                      id="floatingInput"
                                      placeholder="Ex.1144"
                                    />
                                    <label htmlFor="floatingInput">
                                      Losses per day (%)
                                    </label>
                                    {formDataErrors.losses_per_day && (
                                      <div className="text-danger">
                                        {formDataErrors.losses_per_day}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                  <label
                                    style={{ fontSize: "11px", color: "white" }}
                                    htmlFor="tes_pumps"
                                  >
                                     TES Pumps
                                  </label>

                                  <div className="form-floating mb-2">
                                    <select
                                      name="tes_pumps"
                                      onChange={handleInputChange}
                                      defaultValue="Default"
                                      className="form-select w-100"
                                      value={formData.tes_pumps}
                                    >
                                      <option value="default">
                                      Select TES Pumps
                                      </option>





                                      <option
                                        value="Same as Secondary Pump"
                                        selected={
                                          formData.tes_pumps ===
                                            "Same as Secondary Pump"
                                        }
                                      >
                                         Same as Secondary Pump
                                      </option>

                                      
                                      <option
                                        value="User Input"
                                        selected={
                                          formData.tes_pumps ===
                                          ("User Input" ||
                                            formData.tes_pumps)
                                        }
                                      >
                                        User Input
                                      </option>

                                      <option
                                        value="Not Applicable"
                                        selected={
                                          formData.tes_pumps ===
                                          "Not Applicable"
                                        }
                                      >
                                        Not Applicable
                                      </option>

                                    </select>
                                    {formDataErrors.tes_pumps && (
                                      <div className="text-danger">
                                        {formDataErrors.tes_pumps}
                                      </div>
                                    )}
                                  </div>
                                  </div>

                                  
                                






                                
                              </div>
                              <br />
                              <div
                                className="row"
                                style={{
                                  display:
                                    formData.tes_pumps === "User Input"
                                      ? "block"
                                      : "none",
                                }}
                              >
                                {formData.tes_pumps}
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-floating mb-2">
                                    <select
                                      name="tes_pumps_type"
                                      onChange={handleInputChange}
                                      id=""
                                      className="form-select w-50"
                                    >
                                      <option value="">
                                        ------------Type------------
                                      </option>
                                      <option
                                        value="Variable"
                                        selected={
                                          formData.tes_pumps_type === "Variable"
                                        }
                                      >
                                        Variable
                                      </option>
                                      <option
                                        value="User Input"
                                        selected={
                                          formData.tes_pumps_type ===
                                          "User Input"
                                        }
                                      >
                                        User Input
                                      </option>
                                    </select>
                                    {formDataErrors.tes_pumps_type && (
                                      <div className="text-danger">
                                        {formDataErrors.tes_pumps_type}
                                      </div>
                                    )}
                                  </div>
                                  <div className="table-responsive">
                                    <table className="table table-blk">
                                      <thead>
                                        <tr className="bg-success">
                                          <th>Chiller&nbsp;Load</th>
                                          <th>Pump Load</th>
                                          <th>Power Input</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>100</td>
                                          <td>
                                            <div className="form-floating">
                                              <input
                                                value={
                                                  formData.ts_pump_load_100
                                                }
                                                onChange={handleInputChange}
                                                name="ts_pump_load_100"
                                                type="number"
                                                pattern="[0-9]*"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Ex.1144"
                                              />
                                              <label htmlFor="floatingInput">
                                                Pump Load
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-floating">
                                              <input
                                                value={
                                                  formData.ts_pump_power_input_100
                                                }
                                                onChange={handleInputChange}
                                                name="ts_pump_power_input_100"
                                                type="number"
                                                pattern="[0-9]*"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Ex.1144"
                                              />
                                              <label htmlFor="floatingInput">
                                                Power Input
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>75</td>
                                          <td>
                                            <div className="form-floating">
                                              <input
                                                value={formData.ts_pump_load_75}
                                                onChange={handleInputChange}
                                                name="ts_pump_load_75"
                                                type="number"
                                                pattern="[0-9]*"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Ex.1144"
                                              />
                                              <label htmlFor="floatingInput">
                                                Pump Load
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-floating">
                                              <input
                                                type="number"
                                                pattern="[0-9]*"
                                                value={
                                                  formData.ts_pump_power_input_75
                                                }
                                                onChange={handleInputChange}
                                                name="ts_pump_power_input_75"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Ex.1144"
                                              />
                                              <label htmlFor="floatingInput">
                                                Power Input
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>50</td>
                                          <td>
                                            <div className="form-floating">
                                              <input
                                                value={formData.ts_pump_load_50}
                                                onChange={handleInputChange}
                                                name="ts_pump_load_50"
                                                type="number"
                                                pattern="[0-9]*"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Ex.1144"
                                              />
                                              <label htmlFor="floatingInput">
                                                Pump Load
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-floating">
                                              <input
                                                value={
                                                  formData.ts_pump_power_input_50
                                                }
                                                onChange={handleInputChange}
                                                name="ts_pump_power_input_50"
                                                type="number"
                                                pattern="[0-9]*"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Ex.1144"
                                              />
                                              <label htmlFor="floatingInput">
                                                Power Input
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>25</td>
                                          <td>
                                            <div className="form-floating">
                                              <input
                                                value={formData.ts_pump_load_25}
                                                onChange={handleInputChange}
                                                name="ts_pump_load_25"
                                                type="number"
                                                pattern="[0-9]*"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Ex.1144"
                                              />
                                              <label htmlFor="floatingInput">
                                                Pump Load
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="form-floating">
                                              <input
                                                value={
                                                  formData.ts_pump_power_input_25
                                                }
                                                onChange={handleInputChange}
                                                name="ts_pump_power_input_25"
                                                type="number"
                                                pattern="[0-9]*"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Ex.1144"
                                              />
                                              <label htmlFor="floatingInput">
                                                Power Input
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="row">
                                <div className="col-lg-12 col-md-12 mb-2">
                                  <FileUpload
                                    formData={formData}
                                    fileDocName="tes_other_document_file"
                                    setFormData={setFormData}
                                  />
                                </div>
                              </div> */}
                               <div className="row">
                            <div className="col-lg-12 col-md-12 mb-2">
                              <label className="colyellow py-1">
                                <span style={{ color: '#FFFF00' }} className="pl-2 pb-2 mb-2">
                                 Document Upload
                                </span> 
                              </label>
                              <div className="form-floating mb-0">
                                <input
                                  type="file"
                                  className="filestyle form-control py-1 px-2"
                                  name="tes_other_document_file"
                                  onChange={handleFileUpload}
                                />
                                {formData.tes_other_document_file ? ( 
                                  <span>
                                    <p style={{ fontWeight: "bold", color: "yellow" }}>
                                      Previous File: &nbsp;
                                      <a 
                                        rel="noreferrer"
                                        style={{ color: "#FFFF00" }}
                                        href={`${BaseURL}${formData?.tes_other_document_file}`}
                                        target="_blank"
                                      >
                                        Click Here
                                      </a>
                                    </p>
                                  </span>
                                ) : ""}

                              </div>
                            </div>
                          </div>
                              <div className="form-group row justify-content-end prj">
                                <div className="col-lg-6 text-left">
                                  <button
                                    onClick={() => navigate("/chiller")}
                                    type="submit"
                                    className="btn btn-pryellow mr-1 waves-effect waves-light"
                                  >
                                    Return to previous step
                                  </button>
                                </div>
                                <div className="col-lg-6 text-right">
                                  <button
                                    type="submit"
                                    className="btn btn-pryellow mr-1 waves-effect waves-light"
                                  >
                                    Go to next step
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        )}
                      </div>
                      {/* <pre style={{ color: "#fff" }}>
                    {JSON.stringify(formData, null, 2)}
                  </pre> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Thermalstore;
