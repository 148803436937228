import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { deleteProjectUrl, totalProjectsUrl } from "../../globalURL";
import Userdashsidebar from "../Userdashsidebar";
import { toast } from "react-toastify";
import Spinner from "../project/Spinner";

const Totalprojects = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dataFetchedRef = useRef(false);
  const [allProjectsList, setAllProjectsList] = useState([]);
  const [formData, setFormData] = useState({
    access_token: localStorage.getItem("access_token"),
  });
  const queryParams = new URLSearchParams(window.location.href);
  let current = 1;
  let pageLimit = 10;
  const [currentPage, setCurrentPage] = useState(current);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    setPageNumber();
  }, []);

  const setPageNumber = () => {
    if (queryParams.get("page")) {
      setCurrentPage(queryParams.get("page"));
      fetchAllProjects(parseInt(queryParams.get("page")), pageLimit);
    } else {
      fetchAllProjects(current, pageLimit);
    }
  };

  const fetchAllProjects = (page, page_limit) => {
    if (page == 0 || page === null || page === "") {
      page = 1;
      setCurrentPage(1);
    }
    const { access_token } = formData;
    axios
      .post(
        totalProjectsUrl,
        JSON.stringify({
          page: page,
          page_limit: page_limit,
          access_token: access_token,
        }),
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.status === "valid") {
          // console.log("response", response.data.data);
          setAllProjectsList(response.data.data);
          // alert(response.data.projects);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something error occured..!, Please try again.");
        // alert("failed")
        console.log(error);
      });
  };

  const deleteProjectNow = (projectId) => {

    const { access_token } = formData;
    axios
      .post(
        deleteProjectUrl,
        JSON.stringify({ project_id: projectId, access_token: access_token }),
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.status === "valid") {
          // console.log("response", response.data.data)
          fetchAllProjects(parseInt(currentPage), pageLimit);
          // alert(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something error occured..!, Please try again.");
        // alert("failed")
        console.log(error);
      });
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Userdashsidebar   currentPage={"totalprojects"} />
          <div className="content-page">
            <div className="content">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <div className="card transparent">
                      <div className="card-body pt-1">
                        <div className="row justify-content-between">
                          <div className="col-lg-12">
                            <div className="page-title-box">
                              <h4 className="page-title pb-0">
                                Total Projects
                              </h4>
                            </div>
                          </div>
                        </div>
                       
                        <div className="row profilepg">
                          <div className="col-lg-12">
                            <div className="card border-0">
                              <div className="card-header bg-success">
                                <h4 className="card-title text-white mb-0">
                                  Recent Projects
                                </h4>
                              </div>
                              <div className="card-body">
                                <div id="resptable">
                                  <table className="table ">
                                    <thead>
                                      <tr>
                                        <th className="text-center">S.No.</th>
                                        <th className="text-center">Date</th>
                                        <th className="text-center">GSAS Project ID</th>
                                        <th className="text-center">Project Name</th>
                                        <th className="text-center">Plant Size (TR)</th>
                                        <th className="text-center">Phase </th>
                                        <th className="text-center">Status </th>
                                        <th className="text-center">Action</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {allProjectsList.projects &&
                                        allProjectsList.projects.map(
                                          (app, index) => (
                                            <tr key={index}>
                                              {/* <td>{formData.title}</td> */}
                                              <td>{index + 1}</td>
                                              {/* <td>{app.project_date}</td> */}
                                              <td>{new Date(app.project_date).toLocaleString()}</td>
                                              <td>{app.gsas_project_id}</td>
                                              <td>{app.project_name || "-"}</td>
                                              <td>{app.plant_size || "-"}</td>
                                              <td>
                                                {app.project_phase || "-"}
                                              </td>
                                              <td className="text-uppercase text-center">
                                                <span
                                                  className={
                                                    app.project_status ===
                                                      "completed"
                                                      ? "badge bg-success"
                                                      : "badge bg-warning"
                                                  }
                                                >
                                                  {app.project_status}
                                                </span>
                                              </td>

                                              <td className="text-center">
                                                <Link
                                                  to={
                                                    "/details?s=" +
                                                    app.project_status +
                                                    "&project_id=" +
                                                    app._id.$oid
                                                  }
                                                  className="btn btn-sm btn-outline-info" style={{ padding: "4px 15px" }}
                                                >
                                                  <i className="fe-eye fa-md"></i> &nbsp;
                                                  View
                                                </Link> &nbsp;&nbsp;

                                                {app.project_status ===
                                                  "completed" ? (
                                                  <Link
                                                    to={
                                                      "/final-result?s=" +
                                                      app.project_status +
                                                      "&project_id=" +
                                                      app._id.$oid
                                                    }
                                                    className="btn btn-sm btn-outline-success" style={{ padding: "4px 15px" }}
                                                  >
                                                    <i className="fe-eye fa-md"></i> &nbsp; Result
                                                  </Link>
                                                ) : (
                                                  <></>
                                                )}&nbsp;&nbsp;
                                                <button
                                                  className="btn btn-outline-danger btn-sm" style={{ padding: "4px 15px" }}
                                                  onClick={() => {
                                                    if (
                                                      window.confirm(
                                                        'Are you sure Delete this "' +
                                                        app.project_name +
                                                        '" Project?'
                                                      )
                                                    ) {
                                                      deleteProjectNow(
                                                        app._id.$oid
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <i className="fe-trash-2 fa-md"></i> &nbsp;
                                                  Delete
                                                </button>


                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              {/* <pre style={{ color: "#fff" }}>
                                                            {JSON.stringify(allProjectsList, null, 2)}
                                                        </pre> */}
                            </div>
                            {allProjectsList.total_pages > 1 && (
                              <div className="row">
                                <div className="align-items-center justify-content-center">
                                  <center>
                                    <nav aria-label="...">
                                      <ul className="pagination">
                                        <li
                                          className={
                                            "page-item " +
                                              (parseInt(currentPage) === null)
                                              ? "disabled"
                                              : ""
                                          }
                                        >
                                          <a
                                            className="page-link"
                                            href={
                                              "/totalprojects?page=" +
                                              (parseInt(currentPage) - 1)
                                            }
                                            tabIndex="-1"
                                          >
                                            Previous
                                          </a>
                                        </li>
                                        {allProjectsList.total_pages &&
                                          Array.apply(
                                            0,
                                            Array(allProjectsList.total_pages)
                                          ).map((x, i) => {
                                            return (
                                              <li
                                                className={
                                                  i + 1 ===
                                                    parseInt(currentPage)
                                                    ? "page-item active"
                                                    : "page-item"
                                                }
                                              >
                                                <a
                                                  className="page-link"
                                                  href={
                                                    "/totalprojects?page=" +
                                                    (i + 1)
                                                  }
                                                >
                                                  {i + 1}
                                                  {/* <span className="sr-only">(current)</span> */}
                                                </a>
                                              </li>
                                            );
                                          })}

                                        {/* <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                                            <li className="page-item active">
                                                                                <a className="page-link" href="#">2 <span className="sr-only">(current)</span></a>
                                                                            </li>
                                                                            <li className="page-item"><a className="page-link" href="#">3</a></li> */}
                                        <li
                                          className={
                                            allProjectsList.total_pages ===
                                              parseInt(currentPage)
                                              ? "page-item disabled"
                                              : "page-item"
                                          }
                                        >
                                          <a
                                            className="page-link"
                                            href={
                                              "/totalprojects?page=" +
                                              (parseInt(currentPage) + 1)
                                            }
                                          >
                                            Next
                                          </a>
                                        </li>
                                      </ul>
                                    </nav>
                                  </center>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Totalprojects;
