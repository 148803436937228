// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gm-style-iw + div {display: none;}
/* button.gm-ui-hover-effect {
    visibility: hidden;
} */

.colBlack{
    color:#000 !important;
}
.butC{
    background: #4ad600;
    color: #fff;
    border: 0px solid;
    border-radius: 500px;
    padding: 3px 24px;

}`, "",{"version":3,"sources":["webpack://./src/Components/maps/map.css"],"names":[],"mappings":"AAAA,oBAAoB,aAAa,CAAC;AAClC;;GAEG;;AAEH;IACI,qBAAqB;AACzB;AACA;IACI,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,oBAAoB;IACpB,iBAAiB;;AAErB","sourcesContent":[".gm-style-iw + div {display: none;}\r\n/* button.gm-ui-hover-effect {\r\n    visibility: hidden;\r\n} */\r\n\r\n.colBlack{\r\n    color:#000 !important;\r\n}\r\n.butC{\r\n    background: #4ad600;\r\n    color: #fff;\r\n    border: 0px solid;\r\n    border-radius: 500px;\r\n    padding: 3px 24px;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
