import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dashboardsidebar from "./Dashboardsidebar";
import axios from "axios";
import Spinner from "./Spinner";
import { BaseURL, projectDetails, pumpsAndOthersAuxUrl, pumpsAndOthersUrl, uploadFileOrImage } from "../../globalURL";
import { toast } from "react-toastify";
import FileUpload from "../dashboard_pages/otherdocumentfile";
import "../../css/background_image.css";


const Auxiliaries = (props) => {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const initialFormData = {
    other_auxiliaries_kwh: "",
    auxiliaries_other_document_file: "",
    _id: localStorage.getItem("project_id"),
    access_token: localStorage.getItem("access_token"),
  };
 
  const [formData, setFormData] = useState(initialFormData);
 
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  function handleFileUpload(event) {
    const formDataImage = new FormData();
    formDataImage.append("file", event.target.files[0]);
    fetch(uploadFileOrImage, {
      method: "POST",
      body: formDataImage,
    })
      .then((r) => r.json())
      .then((data) => {
        // console.log("sss",data["data"]["full_file_address"]);
        setFormData({
          ...formData,
          ["auxiliaries_other_document_file"]: data["data"]["full_file_address"],
        });
      });

    // console.log("fileDocName", fileDocName);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (formData.other_auxiliaries_kwh === "") {
      navigate("/cooling-towers");
    } else {
      try {
        const response = await axios.post(
          pumpsAndOthersAuxUrl,
          JSON.stringify(formData),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setIsLoading(false);
        if (response?.data?.data?.id) {
          const { id } = response?.data?.data;
          navigate("/cooling-towers");
        } else {
          // alert(`${response.data.message}`);
          toast.error(response.data.message);
        }
      } catch (error) {
        setIsLoading(false);
        // alert("An error occurred while submitting the form.");
        toast.error("Something error occured..!, Please try again.");
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    axios
      .post(
        projectDetails,
        JSON.stringify({ project_id: localStorage.getItem("project_id") }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        let result = res["data"]["data"];
        setFormData(result);
        setIsLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);

  const AuxiliaryData = () => {
    return (
      <>
        <div className="content-page mt-0">
          <div className="content">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="card transparent">
                    <div className="card-body pt-1">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="page-title-box float-left mr-2">
                            <h4 className="page-title mb-0 pb-0">
                              Auxiliaries
                            </h4>
                          </div>
                        </div>
                      </div>
                      <form action="" onSubmit={handleSubmit}>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-floating mb-2">
                            <input
                              type="number"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Ex.1144"
                              value={formData?.other_auxiliaries_kwh}
                              onChange={handleChange}
                              name="other_auxiliaries_kwh"
                              pattern="[0-9]*"
                            />
                            <label htmlFor="floatingInput">
                              {`Other Auxiliaries (${formData?.selected_units}h)`}
                            </label>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-lg-12 col-md-12 mb-2">
                            <FileUpload formData={formData} fileDocName="auxiliaries_other_document_file" setFormData={setFormData}  />

                          </div>
                        </div> */}

<div className="row">
                            <div className="col-lg-12 col-md-12 mb-2">
                              <label className="colyellow py-1">
                                <span style={{ color: '#FFFF00' }} className="pl-2 pb-2 mb-2">
                                 Document Upload
                                </span> 
                              </label>
                              <div className="form-floating mb-0">
                                <input
                                  type="file"
                                  className="filestyle form-control py-1 px-2"
                                  name="auxiliaries_other_document_file"
                                  onChange={handleFileUpload}
                                />
                                {formData.auxiliaries_other_document_file ? ( 
                                  <span>
                                    <p style={{ fontWeight: "bold", color: "yellow" }}>
                                      Previous File: &nbsp;
                                      <a 
                                        rel="noreferrer"
                                        style={{ color: "#FFFF00" }}
                                        href={`${BaseURL}${formData?.auxiliaries_other_document_file}`}
                                        target="_blank"
                                      >
                                        Click Here
                                      </a>
                                    </p>
                                  </span>
                                ) : ""}

                              </div>
                            </div>
                          </div>
                        <div className="form-group row justify-content-end prj">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-left">
                            <button
                              type="submit"
                              onClick={() => navigate("/pumps")}
                              className="btn btn-pryellow mr-1 waves-effect waves-light"
                            >
                              Return to previous step
                            </button>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6  text-right">
                            <button
                              type="submit"
                              className="btn btn-pryellow mr-1 waves-effect waves-light"
                            >
                              Go to next step
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Dashboardsidebar currentPage={"auxaliries"} />
          {AuxiliaryData()}
        </>
      )}

    
    </>
  );
};

export default Auxiliaries;
