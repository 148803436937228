// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-box{
    margin: auto;
    background-image:linear-gradient(to left, rgb(0 12 46), rgb(0 32 109));
    height:80%;
}
.right-box{
    margin: auto;
    background-color: #fff;
    height:80%;
}
.vizcontent{
    padding: 15px;
}


.middleDiv{
    /* width: 400px;
    height: 250px; */
    /* background-color: red; */
    
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    
    margin: auto;
}
.txtWhite{
    color: #fff !important;
}

.colBlue{
    background-color: royalblue;
    /* height: 100px;
    width: 100px; */
    margin:  auto;position: absolute;
    top:1;
    bottom: 0;
    left: 0;
    right: 0;
}
.colGreen{
    background-color: green;
    /* height: 100px;
    width: 100px;  */
    margin: auto;
    position: absolute;
    top:0;
    bottom: 1;
    left: 0;
    right: 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/visualization/visu.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sEAAsE;IACtE,UAAU;AACd;AACA;IACI,YAAY;IACZ,sBAAsB;IACtB,UAAU;AACd;AACA;IACI,aAAa;AACjB;;;AAGA;IACI;oBACgB;IAChB,2BAA2B;;IAE3B,kBAAkB;IAClB,KAAK;IACL,SAAS;IACT,OAAO;IACP,QAAQ;;IAER,YAAY;AAChB;AACA;IACI,sBAAsB;AAC1B;;AAEA;IACI,2BAA2B;IAC3B;mBACe;IACf,aAAa,CAAC,kBAAkB;IAChC,KAAK;IACL,SAAS;IACT,OAAO;IACP,QAAQ;AACZ;AACA;IACI,uBAAuB;IACvB;oBACgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,KAAK;IACL,SAAS;IACT,OAAO;IACP,QAAQ;AACZ","sourcesContent":[".left-box{\r\n    margin: auto;\r\n    background-image:linear-gradient(to left, rgb(0 12 46), rgb(0 32 109));\r\n    height:80%;\r\n}\r\n.right-box{\r\n    margin: auto;\r\n    background-color: #fff;\r\n    height:80%;\r\n}\r\n.vizcontent{\r\n    padding: 15px;\r\n}\r\n\r\n\r\n.middleDiv{\r\n    /* width: 400px;\r\n    height: 250px; */\r\n    /* background-color: red; */\r\n    \r\n    position: absolute;\r\n    top:0;\r\n    bottom: 0;\r\n    left: 0;\r\n    right: 0;\r\n    \r\n    margin: auto;\r\n}\r\n.txtWhite{\r\n    color: #fff !important;\r\n}\r\n\r\n.colBlue{\r\n    background-color: royalblue;\r\n    /* height: 100px;\r\n    width: 100px; */\r\n    margin:  auto;position: absolute;\r\n    top:1;\r\n    bottom: 0;\r\n    left: 0;\r\n    right: 0;\r\n}\r\n.colGreen{\r\n    background-color: green;\r\n    /* height: 100px;\r\n    width: 100px;  */\r\n    margin: auto;\r\n    position: absolute;\r\n    top:0;\r\n    bottom: 1;\r\n    left: 0;\r\n    right: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
