import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deletePaymentTransacionUrl, feedbacksUrl, paymentTransactionListUrl } from "../../globalURL";
import Userdashsidebar from "../Userdashsidebar";
import { toast } from "react-toastify";
import Spinner from "../project/Spinner";

const PaymentsTransactions = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dataFetchedRef = useRef(false);

    const [formData, setFormData] = useState({
        title: "",
        description: "",
        access_token: localStorage.getItem("access_token"),
    });

    const [formDataErrors, setFormDataErrors] = useState({
        title: "",
        description: "",
    });

    const [allRequests, setAllRequests] = useState([]);

    const { access_token } = formData;


    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        setIsLoading(true);
        fetchRequests();
    }, []);

    const fetchRequests = async () => {
        try {
            const response = await axios.post(
                paymentTransactionListUrl,
                JSON.stringify({ access_token: access_token }),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            // console.log("response", response);
            setIsLoading(false);
            if (response?.data?.status === "valid") {
                // console.log("response", response.data.data);
                // setFormData(response.data.data)
                setAllRequests(response.data.data);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while submitting the form");
            console.log(error);
            // alert("An error occurred while submitting the form.");
        }
    };
    const deleteTransaction = (transId) => {

        const { access_token } = formData;
        axios
          .post(
            deletePaymentTransacionUrl,
            JSON.stringify({ id: transId, access_token: access_token }),
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => {
            setIsLoading(false);
            if (response?.data?.status === "valid") {
              // console.log("response", response.data.data)
              fetchRequests();
              // alert(response.data.message);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            toast.error("Something error occured..!, Please try again.");
            // alert("failed")
            console.log(error);
          });
      };

    return (
        <>
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    <Userdashsidebar currentPage={"payments_transactions"} />
                    <div className="content-page">
                        <div className="content">
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-lg-10">
                                        <div className="card transparent">
                                            <div className="card-body pt-1">
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-12">
                                                        <div className="page-title-box">
                                                            <h4 className="page-title">Payment Transactions</h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="row profilepg">
                                                            <div className="col-lg-12">
                                                                <div id="resptable">
                                                                    <table className="table table-blk">
                                                                        <thead>
                                                                            <tr className="bg-success">
                                                                                <th>S.no</th>
                                                                                <th>Transaction Id</th>

                                                                                <th>Package Name</th>
                                                                                <th>Total Amount</th>
                                                                                <th>Payment Status</th>
                                                                                <th>Created At</th>
                                                                                <th>Payment  Transaction Id</th>
                                                                                <th>Completed At</th>
                                                                                <th>Total  Projects</th>
                                                                                <th>Remaining  Projects</th>
                                                                                <th>Expiry Date </th>
                                                                                <th>Is Expired  </th>
                                                                                <th>Actions  </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {/* //allRequests */}
                                                                            {allRequests &&
                                                                                allRequests.map((app) => (
                                                                                    <tr key={allRequests.indexOf(app)}>
                                                                                        <td data-title="S.No.">
                                                                                            {allRequests.indexOf(app) + 1}
                                                                                        </td>
                                                                                        <td data-title="Id">
                                                                                            {app._id}
                                                                                        </td>
                                                                                        <td >{app.package_title}</td>
                                                                                        <td >${app.total_amount}</td>

                                                                                        <td >
                                                                                            <span
                                                                                                className={
                                                                                                    app.payment_status ===
                                                                                                        "Completed"
                                                                                                        ? "badge bg-success"
                                                                                                        : "badge bg-warning"
                                                                                                }
                                                                                            >
                                                                                                {app.payment_status}
                                                                                            </span>
                                                                                        </td>

                                                                                        <td >{app.created_at}</td>
                                                                                        <td >{app.payment_transaction_id}</td>
                                                                                        <td >{app.completed_at}</td>
                                                                                        <td >{app.no_of_projects_allow}</td>
                                                                                        <td >{app.no_of_projects_remain}</td>
                                                                                        <td >{app.expiry_date}</td>
                                                                                        <td >
                                                                                            <span
                                                                                                className={
                                                                                                    app.is_expired ===
                                                                                                        "No"
                                                                                                        ? "badge bg-success"
                                                                                                        : "badge bg-danger"
                                                                                                }
                                                                                            >
                                                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                {app.is_expired || "--"}
                                                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                            </span>

                                                                                        </td>
                                                                                        <td >
                                                                                            {app.payment_status !== "Completed" && <button className="btn btn-danger btn-xs" onClick={()=>deleteTransaction(app._id)} >
                                                                                                Delete
                                                                                            </button>}
                                                                                            
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <pre style={{ color: "#fff" }}>
                    {JSON.stringify(formData, null, 2)}
                  </pre> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default PaymentsTransactions;
