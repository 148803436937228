import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { privacyURL } from "../../globalURL";
import Userdashsidebar from "../Userdashsidebar";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from "../project/Spinner";

const Privacypolicy = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dataFetchedRef = useRef(false);
  const [cmsData, setCmsData] = useState({
    title: "",
    description: "",
  });
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    fetchRequests();
  });

  const fetchRequests = async (event) => {
    try {
      const response = await axios.get(
        privacyURL,
        JSON.stringify({ access_token: localStorage.getItem("access_token") }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("response", response);
      setIsLoading(false);
      if (response?.data?.status === "valid") {
        // console.log("response", response.data.data);
        // setFormData(response.data.data)
        setCmsData(response.data.data);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while submitting the form");
      // alert("An error occurred while submitting the form.");
      console.log(error);
    }
  };
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div>
            <Userdashsidebar />
            <div className="content-page">
              <div className="content">
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <div className="card transparent">
                        <div className="card-body pt-1">
                          <div className="row pt-lg-0 pt-3">
                            <div className="col-lg-12">
                              <ul className="cmsmenu">
                                <li>
                                  <Link to="/aboutus">AboutUs</Link>
                                </li>
                                <li>
                                  <Link to="/termsandconditions">
                                    Terms &amp; Conditions
                                  </Link>
                                </li>
                                <li className="active">
                                  <Link to="/privacypolicy">
                                    Privacy Policy
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-lg-12">
                              <div className="page-title-box">
                                <h4 className="page-title">{cmsData.title}</h4>
                              </div>
                            </div>
                          </div>
                          <div className="row cmsnewblock">
                            <div className="col-lg-12">
                              {cmsData.description}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Privacypolicy;
