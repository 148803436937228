import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import {
  mainPackages,
  subPackages,
  allPackages,
  packagesUrl
} from '../../globalURL'
import Userdashsidebar from '../Userdashsidebar'
import { toast } from 'react-toastify'
import Spinner from '../project/Spinner'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
const Packages = () => {
  const [isLoading, setIsLoading] = useState(false)
  const dataFetchedRef = useRef(false)
  const [packagesData, setpackagesData] = useState([])
  const [currentStep, setCurrentStep] = useState(1)
  const [activeTab, setActiveTab] = useState(0)
  const [stepthree, setStepthree] = useState(true)
  const [stepfour, setStepfour] = useState(0)
  const handleStepThree = () => {
    setStepthree(!stepthree)
  }
  const handleStepFour = index => {
    setStepfour(index)
  }
  const handleTabClick = index => {
    setActiveTab(index)
  }
  useEffect(() => {
    if (dataFetchedRef.current) return
    dataFetchedRef.current = true
    fetchPackages()
  }, [])
  const fetchPackages = async e => {
    setIsLoading(true)
    try {
      const response = await axios.post(
        packagesUrl,
        JSON.stringify({ access_token: localStorage.getItem('access_token') }),
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      // console.log("response", response);
      setIsLoading(false)
      if (response?.data?.status === 'valid') {
        // console.log("response", response.data.data)

        response.data.data.map(catPack => {
          if (catPack.packages) {
            catPack.packages.map(selItem => {
              selItem.selected = false
              selItem.with_advisory_selected = false
            })
          }
        })
        setpackagesData(response.data.data)
        // alert(response.data.message);
      }
    } catch (error) {
      setIsLoading(false)
      toast.error('An error occurred while submitting the form')
      console.log(error)
      // alert("An error occurred");
    }
  }
  const fetchSubPackages = async categoryId => {
    setIsLoading(true)
    try {
      const response = await axios.post(
        subPackages,
        JSON.stringify({
          access_token: localStorage.getItem('access_token'),
          category_id: categoryId
        }),
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      // console.log("response", response);
      setIsLoading(false)
      if (response?.data?.status === 'valid') {
        // console.log("response", response.data.data)
        setpackagesData(response.data.data)
        // alert(response.data.message);
      }
    } catch (error) {
      setIsLoading(false)
      toast.error('An error occurred while submitting the form')
      console.log(error)
      // alert("An error occurred");
    }
  }
  // const updateSteps = () => {
  //   if (currentStep == 3) {
  //     setCurrentStep(1);
  //   } else if (currentStep === 2) {
  //     setCurrentStep(1);
  //   } else {
  //     setCurrentStep(1);
  //   }
  //   fetchPackages();
  // };

  const updateSteps = () => {
    setCurrentStep(1)
    fetchPackages()
  }
  const changeSelectBoxFun = e => {
    const selectedValue = e.target.value
    if (packagesData) {
      const updatedPackagesData = packagesData.map(catPack => {
        if (catPack.packages) {
          const updatedPackages = catPack.packages.map(selItem => {
            if (selItem._id === selectedValue) {
              return { ...selItem, selected: true }
            } else {
              return { ...selItem, selected: false }
            }
          })
          return { ...catPack, packages: updatedPackages }
        }

        return catPack
      })
      setpackagesData(updatedPackagesData)
    }
  }
  const checkboxChainging = (selectedValue, event) => {
    // alert((event.target.checked)? "Y":"N");
    if (packagesData) {
      const updatedPackagesData = packagesData.map(catPack => {
        if (catPack.packages) {
          const updatedPackages = catPack.packages.map(selItem => {
            if (selItem._id === selectedValue) {
              if (event.target.checked) {
                return { ...selItem, with_advisory_selected: true }
              } else {
                return { ...selItem, with_advisory_selected: false }
              }
            } else {
              return { ...selItem, with_advisory_selected: false }
            }
          })
          return { ...catPack, packages: updatedPackages }
        }

        return catPack
      })
      setpackagesData(updatedPackagesData)
    }
  }
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div>
            <Userdashsidebar currentPage={'packages'} />
            <div className='content-page mt-0  class-back-ground border-colo-top'>
              <div className='content'>
                <div className='container-fluid'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-10'>
                      <div className='card transparent'>
                        <div className='card-body pt-1'>
                          <div className='row justify-content-center'>
                            <div className='col-lg-10'>
                              <div className='page-title-box mb-5'>
                                {/* <h4 className="page-title">Packages</h4> */}
                                <h4 className='page-title colyellow-chiller'>
                                  Arcadia Subscription Plans
                                </h4>
                                <p className='p-color'>
                                  Join the growing number of project
                                  stakeholders who have relied on Arcadia Suite.
                                  Choose from our flexible subscription plans
                                  that fit your energy assessment needs and
                                  budget.
                                  <br />
                                  <span>
                                    *To inquire about PCR activities, kindly get
                                    in touch with GORD.
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className='col-lg-2'>
                              {/* <button
                                         className="btn btn-md btn-success"
                                         style={{ padding: "4px 20px" }}
                                         >
                                         <i className="fe-arrow-left fa-lg"></i> Back
                                         &nbsp;
                                         </button> */}
                            </div>
                          </div>

                          <ul className='nav nav-tabs mb-3'>
                            {packagesData &&
                              packagesData.map((item, index) => (
                                <li
                                  key={item._id}
                                  style={{ marginRight: '50px' }}
                                  className={`nav-item ${
                                    activeTab === index ? 'active' : ''
                                  }`}
                                >
                                  <Link
                                    to={'#'}
                                    className={`nav-link tabone ${
                                      activeTab === index ? 'active' : ''
                                    }`}
                                    onClick={() => handleTabClick(index)}
                                  >
                                    {item.title}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                          <div className=''>
                            {packagesData &&
                              packagesData.map((item, index) => {
                                if (activeTab === index) {
                                  if (
                                    item.sub_categories &&
                                    item.sub_categories.length > 0
                                  ) {
                                    return item.sub_categories.map(
                                      (sitem, si) => {
                                        if (sitem.packages.length > 1) {
                                          return (
                                            <>
                                              <div
                                                style={{
                                                  borderTop:
                                                    '1px solid #04d7e6',
                                                  borderBottom:
                                                    stepfour === si
                                                      ? '0px '
                                                      : '1px solid #04d7e6'
                                                }}
                                                className='col-lg-12 py-2 mb-4'
                                              >
                                                <Link
                                                  style={{
                                                    color: 'rgb(2, 180, 255)'
                                                  }}
                                                  className={`tab-pane fade ${
                                                    activeTab === index
                                                      ? 'show active'
                                                      : ''
                                                  }`}
                                                  onClick={() =>
                                                    handleStepFour(si)
                                                  }
                                                >
                                                  <div className='row d-flex align-items-center'>
                                                    {stepfour === si ? (
                                                      <FontAwesomeIcon
                                                        style={{
                                                          color: '#00ff00',
                                                          fontSize: '20px'
                                                        }}
                                                        icon={faCaretDown}
                                                      />
                                                    ) : (
                                                      <FontAwesomeIcon
                                                        style={{
                                                          color: '#00ff00',
                                                          fontSize: '20px'
                                                        }}
                                                        icon={faCaretRight}
                                                      />
                                                    )}
                                                    &nbsp; {sitem.title}
                                                  </div>
                                                </Link>
                                              </div>
                                              <div className='row'>
                                                {sitem.packages.length > 0 &&
                                                  sitem.packages.map(
                                                    (pack, pindex) => {
                                                      return (
                                                        <div className='col-lg-6'>
                                                          {stepfour === si && (
                                                            <div className='table-responsive'>
                                                              <table className='table packages-table'>
                                                                <thead>
                                                                  <tr className='text-center'>
                                                                    <td
                                                                      colSpan={
                                                                        10
                                                                      }
                                                                    >
                                                                      <h4
                                                                        style={{
                                                                          fontWeight:
                                                                            '600',
                                                                          color:
                                                                            'rgb(2 180 255)'
                                                                        }}
                                                                        className='text-center mb-4'
                                                                      >
                                                                        {
                                                                          pack.title
                                                                        }
                                                                      </h4>
                                                                      <Link
                                                                        className='btn btn-pryellow'
                                                                        to={
                                                                          '/buypackage?t=' +
                                                                          pack.title +
                                                                          '&cid=' +
                                                                          pack.category_id +
                                                                          '&sid=' +
                                                                          pack.sub_category_id +
                                                                          '&pid=' +
                                                                          pack._id +
                                                                          '&des=' +
                                                                          pack.desc +
                                                                          '&wa=' +
                                                                          pack.with_advisory +
                                                                          '&was=' +
                                                                          pack.with_advisory_selected
                                                                        }
                                                                      >
                                                                        Buy Now
                                                                      </Link>
                                                                    </td>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  <tr>
                                                                    <td className='text-white with-border-bottom'>
                                                                      Package
                                                                      Title
                                                                    </td> 
                                                                    <td>:</td>
                                                                    {/* <td>{packageDetails.title} </td> */}
                                                                    <td>
                                                                      {
                                                                        pack.title
                                                                      }{' '}
                                                                    </td>
                                                                  </tr>

                                                                  <tr>
                                                                    <td className='text-white'>
                                                                      Package
                                                                      Details
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                      {
                                                                        pack.desc
                                                                      }{' '}
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td className='text-white'>
                                                                      Package
                                                                      Validity
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                      {
                                                                        pack.validity
                                                                      }{' '}
                                                                      Months
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td className='text-white'>
                                                                      No. Of
                                                                      Projects
                                                                      Allowed
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                      {
                                                                        pack.no_of_projects_allow
                                                                      }{' '}
                                                                      Project
                                                                    </td>
                                                                  </tr>

                                                                  <tr>
                                                                    <td className='text-white'>
                                                                      Air Cooled
                                                                      Chiller
                                                                      Enable
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                      {
                                                                        pack.air_cooled_chiller_enable
                                                                      }{' '}
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td className='text-white'>
                                                                      Water
                                                                      Cooled
                                                                      Chiller
                                                                      Enable
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                      {
                                                                        pack.water_cooled_chiller_enable
                                                                      }{' '}
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td className='text-white'>
                                                                      Thermal
                                                                      Store
                                                                      Enable
                                                                      (TES)
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                      {
                                                                        pack.thermal_store_enable
                                                                      }{' '}
                                                                    </td>
                                                                  </tr>

                                                                  <tr>
                                                                    <td className='text-white'>
                                                                      Package
                                                                      Price
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                      $
                                                                      {
                                                                        pack.price
                                                                      }{' '}
                                                                    </td>
                                                                  </tr>

                                                                  <tr>
                                                                    <td className='text-white'>
                                                                      Total
                                                                      Payable
                                                                      Amount
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                      $
                                                                      {
                                                                        pack.price
                                                                      }
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                          )}
                                                        </div>
                                                      )
                                                    }
                                                  )}
                                              </div>
                                            </>
                                          )
                                        } else {
                                          return (
                                            <div className='row'>
                                              {/* {sitem.title} :   {sitem.packages.length} */}
                                              {sitem.packages.length > 0 &&
                                                sitem.packages.map(
                                                  (pack, pindex) => (
                                                    <>
                                                      <div className='col-lg-12'>
                                                        <div className='table-responsive'>
                                                          <table className='table packages-table'>
                                                            <thead>
                                                              <tr className='text-center'>
                                                                <td
                                                                  colSpan={10}
                                                                >
                                                                  <h4
                                                                    style={{
                                                                      fontWeight:
                                                                        '600',
                                                                      color:
                                                                        '#FFFF00'
                                                                    }}
                                                                    className='text-center mb-4'
                                                                  >
                                                                    {pack.title}
                                                                  </h4>
                                                                  <Link
                                                                    style={{
                                                                      backgroundColor:
                                                                        '#FFFF00',
                                                                      color:
                                                                        '#000',
                                                                      fontWeight:
                                                                        '600'
                                                                    }}
                                                                    className='btn '
                                                                    to={
                                                                      '/buypackage?t=' +
                                                                      pack.title +
                                                                      '&cid=' +
                                                                      pack.category_id +
                                                                      '&sid=' +
                                                                      pack.sub_category_id +
                                                                      '&pid=' +
                                                                      pack._id +
                                                                      '&des=' +
                                                                      pack.desc +
                                                                      '&wa=' +
                                                                      pack.with_advisory +
                                                                      '&was=' +
                                                                      pack.with_advisory_selected
                                                                    }
                                                                  >
                                                                    Buy Now
                                                                  </Link>
                                                                </td>
                                                              </tr>
                                                            </thead> 
                                                            <tbody>
                                                              <tr>
                                                                <td className='text-white with-border-bottom'>
                                                                  Package Title
                                                                </td>
                                                                <td>:</td>
                                                                {/* <td>{packageDetails.title} </td> */}
                                                                <td>
                                                                  {pack.title}{' '}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td className='text-white'>
                                                                  Package
                                                                  Details
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                  {pack.desc}{' '}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td className='text-white'>
                                                                  Note
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                  {' '}
                                                                  {pack.note ||
                                                                    '-'}{' '}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td className='text-white'>
                                                                  Package
                                                                  Validity
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                  {
                                                                    pack.validity
                                                                  }{' '}
                                                                  Months
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td className='text-white'>
                                                                  No. Of
                                                                  Projects
                                                                  Allowed
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                  {
                                                                    pack.no_of_projects_allow
                                                                  }{' '}
                                                                  Project
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td className='text-white'>
                                                                  Air Cooled
                                                                  Chiller Enable
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                  {
                                                                    pack.air_cooled_chiller_enable
                                                                  }{' '}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td className='text-white'>
                                                                  Water Cooled
                                                                  Chiller Enable
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                  {
                                                                    pack.water_cooled_chiller_enable
                                                                  }{' '}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td className='text-white'>
                                                                  Thermal Store
                                                                  Enable (TES)
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                  {
                                                                    pack.thermal_store_enable
                                                                  }{' '}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td className='text-white'>
                                                                  Package Price
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                  ${pack.price}{' '}
                                                                </td>
                                                              </tr>{' '}
                                                              <tr>
                                                                <td className='text-white'>
                                                                  Total Payable
                                                                  Amount
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                  ${pack.price}
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      </div>
                                                    </>
                                                  )
                                                )}
                                            </div>
                                          )
                                        }
                                      }
                                    )
                                  }
                                }
                              })}
                          </div>
                          {/* <pre style={{ color: "#fff" }}>
                 {JSON.stringify(packagesData, null, 2)}
                 </pre> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Packages
