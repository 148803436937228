import React, { useEffect, useState, useRef } from 'react';
// import "./visu.css";
import VisualForm from "./visualform";
import Userdashsidebar from "../Userdashsidebar";
import { Loader } from "@googlemaps/js-api-loader";
import { GoogleMapApiKey } from "../../globalURL";
import MarkerImg from '../../images/markers.png'
import axios from 'axios';
import { totalProjectsUrl } from '../../globalURL';
import { toast } from 'react-toastify';
import UseServices from "../../services/api"
import { getAccessTokenLocal, getProjectLocalId } from '../../common/common_helper';
import { Map } from '../maps/Map';
const VisualisationLanding = () => {
  const dataFetchedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [markersList, setMarkersList] = useState([])
  const [centerObj, setCenterObj] = useState({
    lat: 25.2854,
    lng: 51.5310
  })

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchAllProjects();

  }, []);

  const fetchAllProjects = async (props) => {
    setIsLoading(true);
   
    UseServices.fetchPostData(totalProjectsUrl, formData)
      .then((response) => {
        setIsLoading(false);
        // console.log("result : ")
        // console.log(response["data"]["projects"].length)
        // set user details based on response data
        if (response.data.projects) {
          let markersListData = [];
          let setLat = true
          // console.log("Pro Len ::::" + response["data"]["projects"].length);
          response["data"]["projects"].map((app, index) => {

            if (app["lat"] !== "" && app["long"] !== "") {
              if (setLat) {
                // alert(index);
                setCenterObj({lat:app["lat"],lng:app["long"]});
                // setDefaultLat(app["lat"]);
                // setDefaultLong(app["long"]);
                setLat = false
              }
              let system_seer = parseFloat(app["system_seer_kw"]).toFixed(2);
              let chiller_seer = parseFloat(app["chiller_seer_kw"]).toFixed(2);
              let system_seer_kw_ton = parseFloat(app["system_seer_kw_ton"]).toFixed(2);
              let chiller_seer_kw_ton = parseFloat(app["chiller_seer_kw_ton"]).toFixed(2);


              let system_seer_op = parseFloat(app["system_seer_op"]).toFixed(2);
              let system_performance_op = parseFloat(app["system_performance_op"]).toFixed(2);
              let gsas_energy_efficiency_op = app["gsas_energy_efficiency_op"]

              let infowindo = "";
              if (app["project_phase"] === "operational") {
                infowindo = "<table class='table'><tbody><tr class='table-default'><th class='text-left'>  Project ID </th><th class='text-left'> : </th><th class='text-left'>  " + app["gsas_project_id"] + "  </th></tr><tr class='table-default'><th class='text-left'> Project Name </th><th class='text-left'> : </th><th class='text-left'>  " + app["project_name"] + "  </th></tr><tr class='table-default'><th class='text-left'> Project Phase  </th><th class='text-left'> : </th><th class='text-left'>  " + app["project_phase"] + "  </th></tr><tr class='table-default'><th class='text-left'> System SEER  </th><th class='text-left'> : </th><th class='text-left'>  " + system_seer_op + "  </th></tr><tr class='table-default'><th class='text-left'> System Performance </th><th class='text-left'> : </th><th class='text-left'>  " + system_performance_op + "  </th></tr><tr class='table-default'><th class='text-left'> Energy Efficiency </th><th class='text-left'> : </th><th class='text-left'>  " + gsas_energy_efficiency_op + "  </th></tr></tbody></table>";

              } else {
                infowindo = "<table class='table'><tbody><tr class='table-default'><th class='text-left'>  Project ID </th><th class='text-left'> : </th><th class='text-left'>  " + app["gsas_project_id"] + "  </th></tr><tr class='table-default'><th class='text-left'> Project Name </th><th class='text-left'> : </th><th class='text-left'>  " + app["project_name"] + "  </th></tr><tr class='table-default'><th class='text-left'> Project Phase  </th><th class='text-left'> : </th><th class='text-left'>  " + app["project_phase"] + "  </th></tr><tr class='table-default'><th class='text-left'> System SEER  </th><th class='text-left'> : </th><th class='text-left'>  " + system_seer + "  </th></tr><tr class='table-default'><th class='text-left'> Chiller SEER </th><th class='text-left'> : </th><th class='text-left'>  " + chiller_seer + "  </th></tr><tr class='table-default'><th class='text-left'> System performance </th><th class='text-left'> : </th><th class='text-left'>  " + system_seer_kw_ton + "  </th></tr><tr class='table-default'><th class='text-left'> Chiller Performance   </th><th class='text-left'> : </th><th class='text-left'>  " + chiller_seer_kw_ton + "  </th></tr></tbody></table>";
              }
              if (app["search_location"]) {
                markersListData.push({
                  name: app["search_location"],
                  location: {
                    lat: app["lat"],
                    lng: app["long"]
                  },
                  project_name: app["project_name"],
                  gsas_project_id: app["gsas_project_id"],
                  project_phase: app["project_phase"],
                  system_seer: system_seer,
                  chiller_seer: chiller_seer,
                  system_seer_kw_ton: system_seer_kw_ton,
                  chiller_seer_kw_ton: chiller_seer_kw_ton,
                  system_seer_op: system_seer_op,
                  system_performance_op: system_performance_op,
                  gsas_energy_efficiency_op: gsas_energy_efficiency_op,
                  info: infowindo
                });
              }
            }
          });
          // console.log(markersListData);
          setMarkersList(markersListData);
        } else {

        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something error occured..!, Please try again.");
        console.error(error);
      });
  }
  const [formData, setFormData] = useState({
    access_token: getAccessTokenLocal(),
    project_id: "",
    project_name: "",
    asbuilt: false,
    asoperated: false,
    asbuilt_and_asoperated: false,
    page: 1,
    page_limit: 200,
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    fetchAllProjects();
  };

  return <>
    <Userdashsidebar currentPage={"visualisation-landing-page"} />
    <div className="content-page">
      <div className="row justify-content-center">
        <div className="col-lg-3"    >
          <VisualForm onClickFun={handleSubmit} formData={formData} setFormData={setFormData} />
        </div>

        <div className="col-lg-7 text-center">
          <Map markersList={markersList} center={centerObj} />
        </div>
      </div>
    </div>
  </>
};

export default VisualisationLanding;
